import PromotionEndDialog from "~~/components/pages/comboPromotion/PromotionEndDialog.vue"

export const useLandingPromotionEndDialog = () => {
  useCommonNormalDialog({
    width: 619,
    popperClass: "sign-in-or-sign-up-wrapper-dialog",
    contentSlots({ close }) {
      return <PromotionEndDialog onBtnClick={close} />
    }
  })
}
