<template>
  <div class="thanks-dialog-container">
    <h2 class="title">{{ dialogTextConfig.topT1 || title.t1 }}</h2>

    <p class="desc">{{ dialogTextConfig.topT2 || title.t2 }}</p>

    <div class="advantage-wrap" @click="goGroupNow">
      <h4 class="advantage-wrap-title">
        {{ dialogTextConfig.centerT1 || useTranslateI18n("download.thanks.advantageTitle") }}
      </h4>

      <ul class="advantage-wrap-list">
        <template v-if="!dialogTextConfig.centerList">
          <li v-for="i in 3" :key="i" class="advantage-wrap-list__item">
            <i class="advantage-wrap-list__item-icon"><Success /></i>
            <span class="advantage-wrap-list__item-text">{{ useTranslateI18n(`download.thanks.list[${i - 1}]`) }}</span>
          </li>
        </template>
        <template v-else>
          <li v-for="(text, i) in dialogTextConfig.centerList" :key="i" class="advantage-wrap-list__item">
            <i class="advantage-wrap-list__item-icon"><Success /></i>
            <span class="advantage-wrap-list__item-text">{{ text }}</span>
          </li>
        </template>
      </ul>

      <div class="advantage-wrap-bottom">
        <button :class="['advantage-wrap-bottom-btn', `advantage-wrap-bottom-btn-${type}`]">
          <CommonAnimationScrollText>
            <img class="icon" :src="imgSrc" alt="" />
            <span class="text">{{
              dialogTextConfig.centerBtn || useTranslateI18n("download.thanks.leadBtnText")
            }}</span>
            <More />
          </CommonAnimationScrollText>
        </button>

        <div class="profile-wrap">
          <img class="profile-wrap__icon" src="@/assets/icons/pages/download/profile_icons.png" alt="" />

          <span
            v-if="showIntroText"
            class="profile-wrap__text"
            v-html="dialogTextConfig.centerDesc || useTranslateI18n(`download.thanks.leadText`)"
          ></span>
        </div>
      </div>
    </div>

    <div class="get-start">
      <span class="get-start-text">{{
        dialogTextConfig.bottomT1 || useTranslateI18n("download.thanks.getStartText")
      }}</span>

      <button :class="[`get-start-btn`]" @click="handleGetStart">
        <CommonAnimationScrollText>
          {{ dialogTextConfig.bottomBtn || useTranslateI18n("download.thanks.getStartBtnText") }}
        </CommonAnimationScrollText>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { JoinFacebookSource } from "~~/constant/mp"
import { PropType } from "vue"
import { GroupLinkType, PointGroupButtonName, ThanksDialogTextType } from "@/composables/api/common"
import { groupLinkTypeImg } from "@/constant/pages/dialog/joinGroupDialog"
import Success from "@/assets/icons/pages/download/success.svg"
import { getThanskDialogTextConfig, IThanksDialogObj } from "@/constant/pages/dialog/thanksDialog"
import { ILocaleValue } from "@/constant/i18n-country"

const props = defineProps({
  textType: {
    type: Number as PropType<ThanksDialogTextType>,
    default: null
  },
  source: {
    type: String as PropType<JoinFacebookSource>,
    default: JoinFacebookSource.Website
  },
  type: {
    type: Number as PropType<GroupLinkType>,
    default: GroupLinkType.LINE
  },
  url: {
    type: String,
    default: ""
  },
  title: {
    type: Object as PropType<{ t1: string; t2: string }>,
    default: () => ({})
  },
  pointPageTitle: {
    type: String,
    default: ""
  }
})

const showIntroText = computed(() => {
  const noShow = [ILocaleValue.ja]
  return !noShow.includes(useI18nCountry().value as ILocaleValue)
})

const dialogTextConfig = computed<IThanksDialogObj>(() => {
  return (props.textType ? getThanskDialogTextConfig(props.textType) : {}) as IThanksDialogObj
})

const imgSrc = computed(() => groupLinkTypeImg[props.type])

const { useCommonMp } = useMp()
const { _pointOfficialPageButtonClick, _pointOfficialGroupButtonClick } = useCommonMp()

const { isClientInlinePage } = useClientPage()

const goGroupNow = useDebounceFn(() => {
  if (unref(isClientInlinePage)) {
    const { $bridge } = useNuxtApp()
    // 判断是否是客户端内打开的，需要通知客户端跳外链
    $bridge.ipcRenderer.send("open-external-link", {
      href: `${props.url}`
    })
  } else {
    _pointOfficialGroupButtonClick({
      buttonName: PointGroupButtonName[props.type],
      pageTitle: props.pointPageTitle
    })
    useLocationOpen(props.url)
  }
}, 300)

function handleGetStart() {
  _pointOfficialPageButtonClick({
    buttonName: "learnMore",
    pageTitle: "afterDownloadPopup",
    path: useMpCommonPath(),
    featureModule: "websiteButtonClick",
    featureName: "clickAfterDownloadPopupButton"
  })
  close()
  if (unref(isClientInlinePage)) {
    const { $bridge } = useNuxtApp()
    // 判断是否是客户端内打开的，需要通知客户端跳外链
    let link = `${process.env.Website_Url}/start`
    $bridge.ipcRenderer.send("open-external-link", {
      href: link
    })
  } else {
    window.open("https://quick-start.evoto.ai/", "_blank")
    // navigateTo("/start")
  }
}
</script>

<style scoped lang="scss">
.thanks-dialog-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 36px;
  > .title {
    text-align: center;
  }

  .title {
    font-weight: 700;
    @include fontSemibold;
    font-size: 40px;
    line-height: 47px;
    margin-bottom: 12px;
    margin-top: 20px;
  }

  .desc {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 32px;
    text-align: center;
  }

  .advantage-wrap {
    /* width: 528px; */
    padding: 32px;
    background-color: $bg-color;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);

    &:hover {
      transform: translateY(-4px);
      box-shadow: 0 4px 20px -12px rgba(0, 0, 0, 0.35);
    }

    &-title {
      @include fontSemibold;
      font-size: 28px;
      line-height: 33px;
      margin-bottom: 20px;
    }

    &-list {
      margin-bottom: 20px;
      &__item {
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }

        &-icon {
          display: flex;
          margin-right: 4px;
        }

        &-text {
          font-size: 18px;
          line-height: 26px;
          color: rgba(0, 0, 0, 0.55);
        }
      }
    }

    &-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-btn {
        /* width: 146px; */
        height: 48px;
        cursor: pointer;
        background: #000000;
        border-radius: 60px;
        color: #ffffff;
        @include fontSemibold;
        animation: scale-in-center 1.2s linear infinite;
        margin-right: 25px;
        :deep(.scroll-text__content) {
          padding: 0 8px;
        }

        @keyframes scale-in-center {
          0% {
            -webkit-transform: scale(1);
            transform: scale(1);
          }
          50% {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
          }

          100% {
            -webkit-transform: scale(1);
            transform: scale(1);
          }
        }

        :deep(.scroll-text__text) {
          display: flex;
          align-items: center;
        }

        .icon {
          width: 24px;
          margin-right: 6px;
        }

        .text {
          margin-right: 8px;
          font-size: 16px;
          line-height: 20px;
        }
      }

      &-btn-1 {
        background-color: #06c755;
      }
      &-btn-2 {
        background-color: #000;
      }
      &-btn-3 {
        background-color: #000;
      }
      &-btn-4 {
        background-color: #624111;
      }
      &-btn-5 {
        background-color: #000;
      }
      &-btn-6 {
        background-color: #3e8dff;
      }

      .profile-wrap {
        display: inline-flex;
        align-items: center;

        &__icon {
          width: 156px;
        }

        &__text {
          font-size: 16px;
          line-height: 26px;
          margin-left: 8px;
        }
      }
    }
  }

  .get-start {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 88px;
    background: #f6f7fb;
    border-radius: 8px;
    margin-top: 16px;
    padding: 0 32px;
    @include fontSemibold;

    &-text {
      font-size: 20px;
      line-height: 23px;
    }

    &-btn {
      width: 116px;
      height: 40px;
      background: $bg-color;
      border-radius: 60px;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      cursor: pointer;
      color: #000;
    }
  }
}
</style>
