import { ICardDetailData } from "@/types/components/pages/combo/comboCardT"

export type IComboProductsWrapper = IComboProductsSingleCard[]

export enum ICardType {
  ComboCard = "comboCard",
  SubCard = "subCard",
  ContactCard = "contactCard"
}

export interface IComboProductsSingleCard {
  type: ICardType
  selectIndex?: number
  topConfig: {
    btnText: string
    topText?: {
      title1?: string
      desc1?: string
      title2?: string
      desc2?: string
    }
    btnTextObj?: {
      title1?: string
      desc1?: string
    }
    productCardList?: (ICardDetailData & INewComboProductListObj)[]
  }
  bottomConfig: {
    extraText?: string
    item1?: {
      textList?: { title: string; list: string[] | { text: string; tip: string }[] }[]
    }
    item2?: {
      textList: string[]
    }
  }
}
