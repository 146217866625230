<template>
  <CommonDialog v-model="showDialog" popper-class="combo-bf_pending_dialog" :dialog-style="dialogStyle">
    <div class="pending-wrapper">
      <img src="@/assets/images/pages/landingComboBf/comingsoon.png" />
      <div class="time">
        <span class="t1">{{ title || useTranslateI18n("landing.landingComboBf.deal") }}</span>
        <CommonCountdown v-if="showCountDown" :value="resultValue" @timeout="countDownHandler" />
      </div>
      <CommonButton :button-style="buttonStyle" @btn-click="goHome"
        ><CommonAnimationScrollText>{{
          useTranslateI18n(`login.invitation.go`)
        }}</CommonAnimationScrollText></CommonButton
      >
    </div>
  </CommonDialog>
</template>

<script setup lang="ts">
import { CSSProperties } from "vue"
import { HOME, PAYMENT_COMBO_ROUTE } from "@/constant/route"

const props = defineProps({
  width: {
    type: Number,
    default() {
      return 438
    }
  },
  title: {
    type: String,
    default: ""
  },
  showCountDown: {
    type: Boolean,
    default() {
      return true
    }
  },
  countDownTimestamp: {
    type: Number,
    default: undefined
  },
  countDownTimeOutHandler: {
    type: Function,
    default: () => {
      return () => ({})
    }
  }
})

let resultValue = ref()
let timeId = setTimeout(() => {
  resultValue.value = props.countDownTimestamp
  clearTimeout(timeId)
})

let showDialog = ref(true)

function closeDialog() {
  showDialog.value = false
}

const countDownHandler = () => {
  props.countDownTimeOutHandler()
  closeDialog()
}

const goHome = () => {
  showDialog.value = false
  navigateTo(unref(PAYMENT_COMBO_ROUTE))
}

let dialogStyle = reactive<CSSProperties>({
  width: props.width,
  backgroundColor: "#fff",
  padding: "40px"
})

const buttonStyle = reactive({
  width: "100%",
  background: "#FFE050",
  color: `rgba(0, 0, 0, 0.85)`,
  borderRadius: "60px",
  height: "42px",
  fontSize: "14px"
})
</script>

<style lang="scss">
.welcome-page-dialog {
  &.combo-bf_pending_dialog {
    .pending-wrapper {
      display: flex;
      flex-direction: column;
      color: #000000;
      align-items: center;
      text-align: center;
      > img {
        width: 104px;
      }
      .time {
        margin-top: 24px;
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 12px 10px;
        .t1 {
          font-size: 22px;
          @include fontSemibold;
        }
        .countdown-wrapper {
          font-size: 20px;
          .countdown-wrapper-item {
            &__text {
              color: #000;
              background: rgba(0, 0, 0, 0.08);
            }
            &__splitter {
              color: #000;
            }
          }
        }
      }
    }
    @include device-max-width-600 {
      .content {
        width: 75%;
        .content-d {
          padding: 30px !important;
          width: 100% !important;
          .time {
            flex-wrap: wrap;
            .countdown-wrapper {
              .countdown-wrapper-item:nth-last-child(1) {
                .countdown-wrapper-item__text {
                  margin-right: 0px;
                }
              }
              .countdown-wrapper-item__text {
                margin-right: 5px;
              }
              .countdown-wrapper-item__splitter {
                display: none;
              }
            }
          }
          .common-button {
            margin-top: 20px;
          }
        }
      }
    }
    @include device-max-width-375 {
      .content {
        width: 70%;
        .content-d {
          padding: 15px !important;
        }
      }
    }
  }
}
</style>
