<template>
  <CommonDialog v-model="showDialog" popper-class="payment-method_dialog" :dialog-style="dialogStyle" @close="close">
    <div class="payment-dialog_wrapper">
      <div class="title">{{ useTranslateI18n(`profile.billingUsage.billing.cardData[2].title`) }}</div>
      <div class="content-p">
        <div
          v-for="(item, i) in paymentMethodDict()"
          :key="i"
          :class="['pay-item', `pay-item-${item.value}`]"
          @click="cardClick(item.value)"
        >
          <div class="bg-mask"></div>
          <div class="pay-item-c">
            <img :src="item.icon" />
            <span>{{ item.label }}</span>
          </div>
        </div>
      </div>
    </div>
  </CommonDialog>
</template>

<script setup lang="ts">
import { CSSProperties } from "vue"
import { paymentMethodDict, Payment_Method } from "@/constant/pages/combo"

interface IProps {
  callback: (...args: any[]) => any
  onClose: (...args: any[]) => any
}
const props = withDefaults(defineProps<IProps>(), {
  callback: () => {
    return null
  },
  onClose: () => {
    return null
  }
})

let dialogStyle = reactive<CSSProperties>({
  width: "480px",
  backgroundColor: "#fff",
  padding: "37px 44px 44px"
})

const showDialog = ref(true)

const cardClick = (value: Payment_Method) => {
  showDialog.value = false
  props.onClose && props.onClose()
  props.callback(value)
}

const close = () => {
  props.onClose && props.onClose()
}
</script>

<style lang="scss">
.welcome-page-dialog {
  &.payment-method_dialog {
    .content-d {
      transition: all 0.2s;
    }
    @include device-max-width-600 {
      .content {
        width: 80%;
        .content-d {
          padding: 20px !important;
          width: 100% !important;
        }
      }
    }
    @include device-max-width-375 {
      .content {
        width: 75%;
        .content-d {
          padding: 20px !important;
        }
        .close-icon {
          right: -32px;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.payment-dialog_wrapper {
  .title {
    font-size: 24px;
    line-height: 29px;
    display: flex;
    justify-content: center;
    @include fontSemibold;
    margin-bottom: 32px;
    text-align: center;
  }
  .content-p {
    display: flex;
    flex-direction: column;
    .pay-item {
      height: 116px;
      width: 100%;
      display: flex;
      align-items: center;
      background-color: #e3f0ff;
      border-radius: 8px;
      cursor: pointer;
      @include ease-in-out;
      position: relative;
      overflow: hidden;
      .bg-mask {
        @include ease-in-out;
        position: absolute;
        width: 159px;
        height: 159px;
        left: -40px;
        top: -66px;
        border-radius: 50%;
        border: 16px solid rgba(255, 255, 255, 0.8);
        z-index: 0;
      }
      .pay-item-c {
        z-index: 1;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        img {
          width: 68px;
          height: 68px;
        }
        span {
          font-size: 16px;
          line-height: 19px;
          margin-left: 19px;
        }
      }

      &.pay-item-1 {
        &:hover {
          background-color: #bfe1ff;
          .bg-mask {
            border: 16px solid rgba(255, 255, 255, 0.5);
          }
        }
      }
      &.pay-item-2 {
        background-color: #f3efff;
        .bg-mask {
          position: absolute;
          @include ease-in-out;
          z-index: 0;
          width: 248px;
          height: 248px;
          border-radius: 12px;
          top: 100px;
          left: -65px;
          transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
          &::before,
          &::after {
            content: "";
            position: absolute;
            background-color: inherit;
            width: 248px;
            height: 248px;
          }
          &::before {
            transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(-50%, 0);
          }
          &::after {
            transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0%, 50%);
          }
        }
        &:hover {
          background-color: #d0c6ed;
          .bg-mask {
            border: 16px solid rgba(255, 255, 255, 0.36);
          }
        }
      }
    }
    .pay-item:nth-child(2) {
      margin-top: 8px;
    }
  }
}
</style>
