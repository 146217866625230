import PaymentMethodDialog from "@/components/pages/payment/PaymentMethodDialog.vue"
import { createVNode, render } from "vue"

let showPaymentMethodDialogFlag = false

export const usePaymentMethodDialog = (callback: (...args: any[]) => any) => {
  if (showPaymentMethodDialogFlag) return

  const vm = createVNode(PaymentMethodDialog, {
    callback,
    onClose() {
      showPaymentMethodDialogFlag = false
    }
  })
  const { vueApp } = useNuxtApp()
  vm.appContext = vueApp._context
  render(vm, document.createElement("div"))

  showPaymentMethodDialogFlag = true
}
