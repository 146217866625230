export interface ICountrySelctModelValue {
  countryValue: number | string
  inputValue: number | string
}

export enum IContactInfo {
  WhatsApp = 1,
  FaceBook,
  Line,
  Zalo,
  Kakao
}
