<template>
  <CommonDialog
    v-model="showContactSalesDialog"
    :dialog-style="contactSalesDialogStyle"
    popper-class="contact-sales-dialog_1"
    @close="close"
  >
    <CommonContactSales
      ref="contactSalesRef"
      :day="showDay"
      @submit="contactSalesSubmit"
      @go-help="contactSalesGohelp"
    />
  </CommonDialog>
</template>

<script setup lang="ts">
import CommonDialog from "@/components/common/Dialog.vue"
import { IConsultationAddObj, ThanksDialogTextType } from "@/composables/api/common"
import { InterfaceStateEnum } from "~~/constant/api/common"
import { HELP_PAGE_PATH } from "@/constant/route" // 邀请注册页面路由
import CommonContactSales from "@/components/common/ContactSales.vue"
import { IContactInfo } from "@/types/components/common/countrySelect"
import { useTranslateI18n } from "~/composables/store/i18n-country"
import { scopeEnum } from "~~/constant/diversion"

let showDay = ref<any>(1)

const initContactUsDay = async () => {
  let data = await getEnumList(21)

  showDay.value = data[0].name
}

initContactUsDay()

const showContactSalesDialog = ref(true)

const contactSalesRef = ref()

let contactSalesDialogStyle = reactive({
  width: 1024,
  backgroundColor: "#fff"
})

const close = () => {}

// 商务点击submit
const contactSalesSubmit = async (formModel) => {
  let resultData = {} as IConsultationAddObj
  for (let key in formModel) {
    if (key !== "phone") {
      resultData[key] = formModel[key]
    }
  }
  resultData.phone = formModel.phone.inputValue
  resultData.phone_country_code =
    resultData.contact_type !== IContactInfo.FaceBook ? formModel.phone.countryValue : undefined
  // 提交表单
  try {
    await postConsultationAdd(resultData)
    useSuccessMessage(useTranslateI18n("login.message.submitSuss"))
    showContactSalesDialog.value = false
    // 显示加入群组
    let { style: type, addr: url } = await getContactGroupUrlV2(
      scopeEnum.contactSales,
      formModel.business_email,
      useReferrer2().value || useReferrer().value
    )
    useDownloadThanksDialog({
      textType: ThanksDialogTextType.Type3,
      pointPageTitle: "afterContactSales",
      url,
      type,
      title: {
        t1: useTranslateI18n(`download.thanks.sub`),
        t2: useTranslateI18n(`download.thanks.descList[2]`, [showDay.value || 1])
      }
    })
  } catch (e) {
    contactSalesRef.value.loadingInstance.close()
    if (e.data.code === InterfaceStateEnum.Limit) {
      contactSalesRef.value?.showVerCodeField()
    }
  }
}

// 商务弹窗跳转
const contactSalesGohelp = () => {
  const { isClientInlinePage } = useClientPage()
  // 客户端不跳转
  if (unref(isClientInlinePage)) {
    return
  }
  showContactSalesDialog.value = false
  navigateTo(unref(HELP_PAGE_PATH))
}
</script>

<style lang="scss">
.welcome-page-dialog {
  &.contact-sales-dialog_1 {
    @include device-max-width-1400 {
      .content {
        width: 80%;
        .content-d {
          width: 100% !important;
        }
        .contact-sales {
          flex-direction: column;
          border-radius: 8px;
          .contact-sales-left {
            width: 100%;
            border-radius: 8px;
            .desc-content {
              margin-bottom: 24px;
            }
            .ev-title {
              bottom: 20px;
            }
          }
        }
      }
    }
    @include device-max-width-900 {
      .content {
        width: 80%;
        .contact-sales {
          .contact-sales-right-content {
            .submit-tip {
              flex-direction: column;
              align-items: center;
            }
          }
        }
      }
    }

    @include device-max-width-600 {
      .content {
        width: 80%;
        .contact-sales {
          .contact-sales-left {
            padding: 20px;
            .title {
              font-size: 24px;
              line-height: 26px;
            }
            .desc-content {
              margin: 10px 0;
            }
            .ev-title {
              display: none;
            }
          }
          .contact-sales-right-content {
            padding: 20px;
            .top-title {
              font-size: 16px;
              line-height: 18px;
            }
          }
        }
      }
    }
    @include device-max-width-375 {
      .content {
        width: 75%;
        .close-icon {
          right: -32px;
        }
      }
    }
  }
}
</style>
