<template>
  <CommonDialog v-model="showDialog" popper-class="combo-bf_timeout_dialog" :dialog-style="dialogStyle">
    <div class="timeout-wrapper">
      <img src="@/assets/images/pages/landingComboBf/soldout.png" />
      <span class="t1">{{ title || useTranslateI18n("landing.landingComboBf.soldOut") }}</span>
      <span v-if="showDesc" class="t2">{{ desc || useTranslateI18n("landing.landingComboBf.soldOutDesc") }}</span>
      <CommonButton :button-style="buttonStyle" @btn-click="goHome"
        ><CommonAnimationScrollText>{{
          useTranslateI18n(`login.invitation.go`)
        }}</CommonAnimationScrollText></CommonButton
      >
    </div>
  </CommonDialog>
</template>

<script setup lang="tsx">
import { CSSProperties } from "vue"
import { PAYMENT_COMBO_ROUTE } from "@/constant/route"

const props = defineProps({
  width: {
    type: Number,
    default() {
      return 438
    }
  },
  title: {
    type: String,
    default() {
      return ""
    }
  },
  desc: {
    type: String,
    default() {
      return ""
    }
  },
  showDesc: {
    type: Boolean,
    default() {
      return true
    }
  }
})

let showDialog = ref(true)

let dialogStyle = reactive<CSSProperties>({
  width: props.width,
  backgroundColor: "#fff",
  padding: "40px"
})

const buttonStyle = reactive({
  width: "100%",
  background: "#FFE050",
  color: `rgba(0, 0, 0, 0.85)`,
  borderRadius: "60px",
  height: "42px",
  fontSize: "14px"
})

const goHome = () => {
  showDialog.value = false
  navigateTo(unref(PAYMENT_COMBO_ROUTE))
}
</script>

<style lang="scss">
.welcome-page-dialog {
  &.combo-bf_timeout_dialog {
    .timeout-wrapper {
      display: flex;
      flex-direction: column;
      color: #000000;
      align-items: center;
      text-align: center;
      > img {
        width: 104px;
      }
      .t1 {
        margin-top: 24px;
        margin-bottom: 24px;
        font-size: 22px;
        @include fontSemibold;
      }
      .t2 {
        font-size: 14px;
        margin-bottom: 40px;
        color: rgba(0, 0, 0, 0.55);
      }
    }
    @include device-max-width-600 {
      .content {
        width: 75%;
        .content-d {
          padding: 30px !important;
          width: 100% !important;
        }
      }
    }
    @include device-max-width-375 {
      .content {
        width: 70%;
        .content-d {
          padding: 15px !important;
        }
      }
    }
  }
}
</style>
