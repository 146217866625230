export enum IPaymentResultType {
  success = "success",
  error = "error"
}

// 订阅成功 订阅失败 升级失败 降级失败 购买流量包失败
export type ITextType = "successSub" | "errorSub" | "upgradeFailed" | "downgradeFailed" | "purchaseFailed"

export type IBtnType = 1 | 2

export interface IBtnListObj {
  type: IBtnType
  text: string
  action?: any
}

export type IBtnList = IBtnListObj[]
