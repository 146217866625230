<template>
  <CommonDialog v-model="showDialog" popper-class="purchase-failed_dialog" :dialog-style="dialogStyle">
    <div class="purchase-wrapper">
      <span class="title">{{ useTranslateI18n(`payment.purchaseDialog.title`) }}</span>
      <span class="content">{{ contentStr }} </span>
    </div>
  </CommonDialog>
</template>

<script setup lang="tsx">
import { CSSProperties } from "vue"
import { useUser } from "@/store/user"
import { IType } from "@/types/pages/dialog/purchaseDialog"
import { getContentValue } from "@/constant/pages/dialog/pruchaseDialog"

interface IProps {
  type: IType
}

const props = withDefaults(defineProps<IProps>(), {
  type: () => IType.Purchase
})

const contentStr = computed(() => {
  return getContentValue(props.type, use_user.getTopBusinessName)
})

let use_user = useUser()

let showDialog = ref(true)

let dialogStyle = reactive<CSSProperties>({
  width: 438,
  backgroundColor: "#fff",
  padding: "36px 45px"
})

// const closeDialog = () => {}
</script>

<style lang="scss">
.welcome-page-dialog {
  &.purchase-failed_dialog {
    .purchase-wrapper {
      display: flex;
      flex-direction: column;
      color: #000000;
      .title {
        font-size: 24px;
        line-height: 29px;
        @include fontSemibold;
      }
      .content {
        font-size: 14px;
        line-height: 17px;
        margin-top: 24px;
      }
    }
    @include device-max-width-600 {
      .content {
        width: 75%;
        .content-d {
          padding: 30px !important;
          width: 100% !important;
        }
      }
    }
    @include device-max-width-375 {
      .content {
        width: 70%;
        .content-d {
          padding: 15px !important;
        }
      }
    }
  }
}
</style>
