<template>
  <CommonDialog v-model="showDialog" popper-class="payment-vn_dialog" :dialog-style="dialogStyle">
    <div class="payment-vn-content">
      <span class="title">{{ useTranslateI18n(`payment.comboPayment.vnDialog.title`) }}</span>
      <div class="pkg-content">
        <span class="pkg-title">
          {{ useTranslateI18n(`payment.comboPayment.paddleDialog.package`) }}
        </span>
        <span class="pkg-name">{{ orderInfo.pkgName }}</span>
        <div class="period">{{ useTranslateI18n(`payment.paddleDialog.period`, [dateStr]) }}</div>
        <div class="credits email">
          <div class="total-c">{{ useTranslateI18n("payment.account") }}</div>
          <div class="one-c">
            <span>{{ topTitle }}</span>
          </div>
        </div>
        <div class="credits">
          <div class="total-c">
            {{ useTranslateI18n(`payment.paddleDialog.credits`, [useNumFormat(orderInfo.credits)]) }}
          </div>
          <div class="one-c">
            <span class="num">{{ priceDetail.perMoney }}</span>
            <span class="unit">&nbsp;{{ useTranslateI18n(`payment.comboPayment.paddleDialog.unit`) }}</span>
          </div>
        </div>
        <transition name="fade">
          <div v-if="priceDetail.discount" class="credits discount">
            <div class="total-c">{{ useTranslateI18n(`payment.comboPayment.vnDialog.discount`) }}</div>
            <div class="one-c">
              <span class="num">{{ priceDetail.discount }}</span>
            </div>
          </div>
        </transition>
        <div class="total">
          <span class="t">{{ useTranslateI18n(`payment.paddleDialog.total`) }}</span>
          <span class="t-t">{{ priceDetail.total }}</span>
        </div>
      </div>
      <div v-if="Number(orderInfo.credits) >= 9000" class="pkg-t-list">
        <span>{{ useTranslateI18n(`payment.paddleDialog.tip1`) }}</span>
        <span>{{ useTranslateI18n(`payment.paddleDialog.tip2`) }}</span>
      </div>
      <div class="pkg-control">
        <div v-if="!showCancelBtn" class="radio-control">
          <el-radio-group v-model="paymentValue">
            <el-radio v-for="(obj, i) in selectRadioArr" :key="i" :label="obj.label">
              <CommonImage :src="obj.src" lazy class="radio-payment-img" />
            </el-radio>
          </el-radio-group>
        </div>
        <div v-if="showCancelBtn" class="discount-input">
          <span class="add-discount">{{ useTranslateI18n(`payment.comboPayment.vnDialog.addDis`) }}</span>
          <el-input
            v-model.trim="discountValue"
            class="country-number"
            clearable="clearable"
            @blur="discountChange"
          ></el-input>
          <transition name="fade">
            <span v-show="discountValueErrorMsgShow" class="error-tip">{{
              useTranslateI18n(`payment.comboPayment.vnDialog.errorTip`)
            }}</span>
          </transition>
        </div>
        <div class="bottom-submit">
          <!-- 同意 通知 -->
          <div class="check-policy">
            <span class="tip">
              {{ useTranslateI18n(`payment.comboPayment.paymentTip1`) }}
            </span>
            <div class="check">
              <el-checkbox v-model="checkPolicy" size="large">
                <span class="check-text">
                  {{ useTranslateI18n(`payment.comboPayment.zhidao`) }}
                </span>
              </el-checkbox>
              <transition name="fade">
                <div v-show="showCheckPolicyTip" class="policy-tip">
                  <Union />
                  <span>
                    {{ useTranslateI18n(`payment.comboPayment.confirm`) }}
                  </span>
                </div>
              </transition>
            </div>
          </div>
          <CommonButton ref="submitRef" :button-style="submitStyle" class="submit-btn" @btn-click="submitClick">
            <CommonAnimationScrollText>
              {{ useTranslateI18n(`payment.comboPayment.vnDialog.cBtn`) }}
            </CommonAnimationScrollText>
          </CommonButton>
          <CommonButton
            v-if="showCancelBtn"
            ref="cancelBtnRef"
            :button-style="cancelStyle"
            class="cancel-btn"
            @btn-click="cancelClick"
          >
            <CommonAnimationScrollText>
              {{ useTranslateI18n(`payment.comboPayment.vnDialog.cancel`) }}
            </CommonAnimationScrollText>
          </CommonButton>
          <span v-if="!showCancelBtn" class="add-discount" @click="addClick">{{
            useTranslateI18n(`payment.comboPayment.vnDialog.addDis`)
          }}</span>
        </div>
      </div>
    </div>
  </CommonDialog>
</template>

<script setup lang="ts">
import { IOrderInfo } from "@/types/components/pages/payment/paymentVnDialog"
import { CSSProperties } from "vue"
import { ElRadio, ElRadioGroup, ElInput } from "element-plus"
import { PaymentRadioSelect, PaymentRadioSelectDict } from "@/constant/pages/combo"
import variables from "@/assets/scss/responsiveExport.module.scss"
import { ElCheckbox } from "element-plus"
import Union from "@/assets/icons/common/union-2.svg"
import { useUser } from "~/store/user"
import { IUserPaymentMethodObj, IUserPaymentPlatform } from "@/composables/api/combo"

const use_user = useUser()

interface IProps {
  isBackUnit: boolean | string
  orderInfo: IOrderInfo
  radioSelectList: IUserPaymentMethodObj[]
  callback: (...args: any[]) => any
}
const props = withDefaults(defineProps<IProps>(), {
  orderInfo: () => {
    return {
      // pkgName: " basic - 500",
      // perMoney: `$0.222`,
      // total: `$39.22`,
      // credits: 600,
      // discount: "$2.22"
    } as IOrderInfo
  },
  isBackUnit: () => false
})

const topTitle = computed(() => {
  return props?.orderInfo.email || use_user.userEmail || `+${use_user.userInfo.phone_cc} ${use_user.userInfo.phone}`
})

let showDialog = ref(true)

let dialogStyle = reactive<CSSProperties>({
  width: 480,
  backgroundColor: "#fff",
  padding: "37.5px 44px 42px"
})

const dateStr = computed(() => {
  let nextDate = new Date()
  nextDate.setFullYear(nextDate.getFullYear() + 1)
  return `${getLocalDateString(new Date(), "YYYY/MM/DD")} - ${getLocalDateString(nextDate, "YYYY/MM/DD")}`
})

const usePaymentSelectHooks = () => {
  let selectRadioArr = ref<({ label: string; src: string } & IUserPaymentMethodObj)[]>([])
  let paymentValue = ref(props.radioSelectList[0].key)
  function initSelectRadio() {
    props.radioSelectList.map((pay) => {
      // passion
      if (pay.platform !== IUserPaymentPlatform.AirWallex) {
        selectRadioArr.value.push({ ...PaymentRadioSelectDict[pay.key], ...pay })
        // airwallex
      } else {
        selectRadioArr.value.push({
          ...{ label: pay.key, src: getCloudAssets(`/images/common/payment-logo/${pay.key}.svg`) },
          ...pay
        })
      }
    })
  }
  return {
    initSelectRadio,

    paymentValue,
    selectRadioArr
  }
}

const useCheckPolicyBox = () => {
  let checkPolicy = ref(false)
  let showCheckPolicyTip = ref(false)
  watch(checkPolicy, (newValue) => {
    if (newValue) {
      showCheckPolicyTip.value = false
    }
  })
  return {
    checkPolicy,
    showCheckPolicyTip
  }
}

const useDiscountHooks = () => {
  let discountValue = ref<string>()
  let discountValueErrorMsgShow = ref(false)
  let priceDetail = reactive({
    discount: "",
    total: props.orderInfo.u_discount_price || props.orderInfo.total,
    perMoney: props.orderInfo.u_discount_per_credit_price || props.orderInfo.perMoney
  })
  const setInitPriceDetail = () => {
    priceDetail.discount = ""
    priceDetail.total = props.orderInfo.u_discount_price || props.orderInfo.total
    priceDetail.perMoney = props.orderInfo.u_discount_per_credit_price || props.orderInfo.perMoney
  }
  const discountChange = async () => {
    if (unref(discountValue)) {
      let pay_config = selectRadioArr.value.find((pay) => pay.key === paymentValue.value)
      let data = await postViteQrDiscount({
        product_id: props.orderInfo.id,
        coupon_code: unref(discountValue),
        platform_id: pay_config.platform
      })
      if (unref(showCancelBtn) && !data.message) {
        discountValueErrorMsgShow.value = false
        // eslint-disable-next-line vue/no-mutating-props
        priceDetail.discount = props.isBackUnit
          ? `${useNumFormat(data.discount)}${data.currency_char}`
          : data.currency_char + useNumFormat(data.discount)
        // eslint-disable-next-line vue/no-mutating-props
        priceDetail.total = props.isBackUnit
          ? `${useNumFormat(data.amount)}${data.currency_char}`
          : data.currency_char + useNumFormat(data.amount)
        priceDetail.perMoney = props.isBackUnit
          ? `${useNumFormat(keepDecimals(data.amount / (props.orderInfo.credits as number), 1))}${data.currency_char}`
          : data.currency_char + useNumFormat(keepDecimals(data.amount / (props.orderInfo.credits as number), 1))
        return true
      }
      if (unref(showCancelBtn) && data.message) {
        discountValueErrorMsgShow.value = true
        setInitPriceDetail()
      }
    } else {
      discountValueErrorMsgShow.value = true
      setInitPriceDetail()
    }
  }
  return {
    discountValueErrorMsgShow,
    discountValue,
    priceDetail,
    setInitPriceDetail,
    discountChange
  }
}

const useBtnClickHooks = () => {
  const submitStyle = reactive({
    width: "100%",
    height: "42px",
    borderRadius: "60px",
    fontSize: "16px",
    color: "#000",
    backgroundColor: variables.bgColor
  })

  const cancelStyle = reactive({
    width: "100%",
    height: "42px",
    borderRadius: "60px",
    fontSize: "16px",
    color: "#000",
    backgroundColor: "rgba(0, 0, 0, 0.1)"
  })

  const showCancelBtn = ref(false)

  let submitRef = ref()

  function closeLoading() {
    submitRef.value && submitRef.value.closeLoading()
  }

  const submitClick = useDebounceFn(async function () {
    if (!unref(checkPolicy)) {
      showCheckPolicyTip.value = true
      useAgreeSubmitDialog({
        text: useTranslateI18n(`payment.comboPayment.paymentTip1`),
        width: 502,
        submit(closeDialog) {
          checkPolicy.value = true
          next()
          closeDialog()
        }
      })
      return
    } else {
      next()
    }
    async function next() {
      let orderInfo = props.orderInfo
      let pay_config = selectRadioArr.value.find((pay) => pay.key === paymentValue.value)
      if (!unref(showCancelBtn)) {
        submitRef.value && submitRef.value.openLoading()
        props.callback && props.callback({ orderInfo, closeLoading, pm_id: unref(paymentValue), pay_config })
      } else {
        // 有设置优惠码传优惠码
        submitRef.value && submitRef.value.openLoading()
        let data = await discountChange()
        if (!data) {
          closeLoading()
          return
        }
        data &&
          props.callback &&
          props.callback({
            orderInfo,
            discount: discountValue.value,
            closeLoading,
            pm_id: unref(paymentValue),
            pay_config
          })
      }
    }
  }, 200)

  const cancelClick = () => {
    showCancelBtn.value = false
    discountValueErrorMsgShow.value = false
    setInitPriceDetail()
    discountValue.value = ""
  }
  const addClick = () => {
    showCancelBtn.value = true
  }

  return {
    submitStyle,
    cancelStyle,
    showCancelBtn,
    submitClick,
    cancelClick,
    addClick,
    submitRef
  }
}

const { initSelectRadio, paymentValue, selectRadioArr } = usePaymentSelectHooks()

const { discountValue, discountValueErrorMsgShow, priceDetail, discountChange, setInitPriceDetail } = useDiscountHooks()

const { submitStyle, cancelStyle, showCancelBtn, submitClick, cancelClick, addClick, submitRef } = useBtnClickHooks()

const { checkPolicy, showCheckPolicyTip } = useCheckPolicyBox()

initSelectRadio()
</script>

<style lang="scss" scoped>
.payment-vn-content {
  display: flex;
  flex-direction: column;
  color: #000;
  justify-content: center;
  font-size: 14px;
  line-height: 17px;
  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    justify-content: center;
    @include fontSemibold;
  }
  .pkg-content {
    display: flex;
    flex-direction: column;
    margin-top: 27.5px;
    padding-bottom: 16px;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.15);
    .pkg-title {
      color: rgba(0, 0, 0, 0.55);
    }
    .pkg-name {
      font-size: 24px;
      line-height: 29px;
      margin-top: 4px;
      @include fontSemibold;
    }
    .period {
      margin-top: 16px;
    }
    .credits {
      margin-top: 17px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &.email {
        flex-wrap: wrap;
      }
      .one-c {
        .num {
          font-size: 16px;
          @include fontSemibold;
          line-height: 19px;
        }
        .unit {
          color: rgba(0, 0, 0, 0.55);
        }
      }
    }
    .discount {
      .one-c {
        .num {
          color: #1cb95a;
        }
      }
    }
    .total {
      margin-top: 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .t {
      }
      .t-t {
        font-size: 16px;
        line-height: 19px;
        @include fontSemibold;
        color: #ff6b00;
      }
    }
  }

  .pkg-t-list {
    padding-top: 17px;
    padding-bottom: 24px;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 17px;
    color: #000;
    > span:nth-child(2n) {
      margin-top: 8px;
    }
  }

  .pkg-control {
    .radio-control {
      margin-top: 24px;
      .radio-payment-img {
        height: 36px;
      }
      :deep(.el-radio-group) {
        label.el-radio {
          margin-bottom: 20px;
          margin-right: 20px;
          height: 36px;
          .el-radio__inner {
            width: 21px;
            height: 21px;
            &::after {
              width: 7px;
              height: 7px;
            }
          }
        }
      }
    }
    .bottom-submit {
      display: flex;
      flex-direction: column;
      align-items: center;
      .check-policy {
        width: 100%;
        background: #ffece8;
        border-radius: 8px;
        padding: 16px;
        margin-top: 36px;
        margin-bottom: 11px;
        display: flex;
        flex-direction: column;
        .tip {
          font-size: 14px;
          line-height: 17px;
          color: #16181a;
        }
        .check {
          display: flex;
          align-items: center;
          margin-top: 16px;
          :deep(.el-checkbox) {
            height: unset;
            color: #16181a;
            .el-checkbox__input.is-checked + .el-checkbox__label {
              color: #16181a;
            }
            .el-checkbox__label {
              padding-left: 6px;
            }
            .el-checkbox__inner {
              width: 16px;
              height: 16px;
              background-color: transparent;
              border-color: #16181a;
            }
            .el-checkbox__inner::after {
              border-color: #16181a;
              left: 5px;
              width: 4px;
              top: 1px;
            }
          }
          .policy-tip {
            padding-left: 9px;
            display: flex;
            align-items: center;
            border-left: 1px solid rgba(0, 0, 0, 0.12);
            color: #fc5555;
            font-size: 14px;
            margin-left: 4px;
            line-height: 17px;
            > span {
              margin-left: 4px;
              flex: 1;
            }
          }
        }
      }
      .submit-btn {
        position: sticky;
        bottom: 0;
        z-index: 1;
      }
      .cancel-btn {
        margin-top: 10px;
      }
      .add-discount {
        font-size: 16px;
        line-height: 19px;
        color: #3083ff;
        text-decoration: underline;
        margin-top: 17px;
        cursor: pointer;
      }
    }
    .discount-input {
      margin-top: 26px;
      display: flex;
      flex-direction: column;
      .add-discount {
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 10px;
      }
      .error-tip {
        margin-top: 10px;
        font-size: 12px;
        line-height: 14px;
        color: #fc5555;
      }
      :deep(.el-input) {
        height: 36px;
        .el-input__wrapper {
          border: 1px solid rgba(0, 0, 0, 0.3);
          border-radius: 8px;
          box-shadow: none;
          .el-input__inner {
            color: #000;
          }
        }
      }
    }
    &.pkg-control-b {
      :deep(.el-radio-group) {
        margin: 20px 0;
      }
    }
  }
}
</style>

<style lang="scss">
.welcome-page-dialog {
  &.payment-vn_dialog {
    @include device-max-width-600 {
      .content {
        width: 80%;
        .content-d {
          width: 100% !important;
        }
      }
    }
    @include device-max-width-375 {
      .content {
        width: 75%;
        .content-d {
          padding: 20px !important;
        }
        .close-icon {
          right: -32px;
        }
      }
    }
  }
}
</style>
