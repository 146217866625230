import PurchaseFailedDialog from "~~/components/common/PurchaseFailedDialog.vue"
import { createVNode, render } from "vue"
import { IType } from "@/types/pages/dialog/purchaseDialog"

export const usePurchaseFailedDialog = (type: IType = IType.Purchase) => {
  const vm = createVNode(PurchaseFailedDialog, {
    ref: "purchaseFailedRef",
    type
  })
  const { vueApp } = useNuxtApp()
  vm.appContext = vueApp._context
  render(vm, document.createElement("div"))
}
