import { postToServerReferrer } from "@/constant/referrer"
import { nanoid } from "nanoid"
import { LocalStorageKey } from "@/constant/cache"
// 传给后端必要数据，后端购买事件上报
export const usePostToServerByPurchase = () => {
  if (
    postToServerReferrer.includes(useReferrer().value as any) ||
    postToServerReferrer.includes(useReferrer2().value as any)
  ) {
    const event_id = nanoid()
    setLocalStorage(LocalStorageKey.SERVER_PAYMENT_EVENT_ID, event_id)
    return {
      org_source_uuid: useReferrer().value || useReferrer2().value,
      event_id,
      url: location.href,
      fbp: useCookie("_fbp").value
    }
  }
}
