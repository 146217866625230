import { IComboProductsWrapper, ICardType } from "@/types/components/pages/comboSub/productsWrapper"

export const getComboCardProductsData: () => IComboProductsWrapper = () => {
  return [
    {
      type: ICardType.ComboCard,
      selectIndex: 2,
      topConfig: {
        btnText: "",
        topText: {
          title1: useTranslateI18n("payment.comboSub.t1"),
          desc1: ""
        },
        btnTextObj: {
          title1: useTranslateI18n("payment.comboProceed.tax"),
          desc1: useTranslateI18n("payment.comboSub.t4")
        },
        productCardList: []
      },
      bottomConfig: {
        item1: {
          textList: [
            {
              title: useTranslateI18n("payment.comboSub.t9"),
              list: [
                useTranslateI18n("payment.upgradeDialog.credits", [0]),
                useTranslateI18n("payment.comboSub.t2", [0]),
                useTranslateI18n("payment.comboSub.t3", [0])

                // useTranslateI18n("payment.upgradeDialog.credits", [3]),
                // useTranslateI18n("payment.comboSub.t25"),
              ]
            }
          ]
        },
        item2: {
          textList: [useTranslateI18n("payment.topCard[1].footerCon[1]")]
        }
      }
    },
    {
      type: ICardType.SubCard,
      selectIndex: 2,
      topConfig: {
        btnText: "",
        topText: {
          title1: useTranslateI18n("payment.comboSub.t33"),
          desc1: ""
        },
        btnTextObj: {
          title1: useTranslateI18n("payment.comboProceed.tax"),
          desc1: useTranslateI18n("payment.comboSub.t4")
        },
        productCardList: []
      },
      bottomConfig: {
        extraText: "銀行振込（請求書払い）をご希望のお客様は、support-jp@evoto.aiまでお問い合わせください。",
        item1: {
          textList: [
            {
              title: useTranslateI18n("payment.comboSub.t9"),
              list: [
                useTranslateI18n("payment.upgradeDialog.credits", [0]),
                useTranslateI18n("payment.comboSub.t2", [0]),
                {
                  text: useTranslateI18n("payment.comboSub.t5"),
                  tip: useTranslateI18n("payment.comboSub.t68")
                },
                useTranslateI18n("payment.comboSub.t78")
                // useTranslateI18n("payment.comboSub.t7")
              ]
            }
          ]
        },
        item2: {
          textList: [useTranslateI18n("payment.topCard[1].footerCon[1]")]
        }
      }
    },
    {
      type: ICardType.ContactCard,
      selectIndex: 0,
      topConfig: {
        btnText: useTranslateI18n("payment.comboPayment.carousel.contact"),
        topText: {
          title1: useTranslateI18n("payment.comboSub.t17"),
          desc1: useTranslateI18n("payment.comboSub.t16"),
          title2: useTranslateI18n("payment.comboPayment.carousel.talk")
        },
        btnTextObj: {
          title1: useTranslateI18n("payment.comboProceed.tax"),
          desc1: useTranslateI18n("payment.comboSub.t4")
        },
        productCardList: []
      },
      bottomConfig: {
        item1: {
          textList: [
            // {
            //   title: useTranslateI18n("payment.comboSub.t9"),
            //   list: [useTranslateI18n("payment.comboSub.t8")]
            // },
            {
              title: useTranslateI18n("payment.comboSub.t10"),
              list: [
                useTranslateI18n("payment.comboSub.t11"),
                useTranslateI18n("payment.comboSub.t12"),
                useTranslateI18n("payment.comboSub.t13"),
                useTranslateI18n("payment.comboSub.t14"),
                useTranslateI18n("payment.comboSub.t15")
              ]
            }
          ]
        },
        item2: {
          textList: [useTranslateI18n("payment.topCard[1].footerCon[1]")]
        }
      }
    }
  ]
}

export const getPaddleComboSubDesc = () => {
  return [
    useTranslateI18n("payment.paddleDialog.cre"),
    useTranslateI18n("payment.comboSub.t34", [{ type: "static", class: "paddle-payment-sub-b" }]),
    useTranslateI18n("payment.comboSub.t35", [
      { type: "static", class: "paddle-payment-sub-b" },
      { type: "static", class: "paddle-payment-sub-b-2" },
      { type: "static", class: "paddle-payment-sub-click" },
      { type: "static", class: "paddle-payment-sub-b-t1" },
      { type: "static", class: "paddle-payment-sub-b-t2" },
      { type: "static", class: "paddle-payment-sub-b-t3" }
    ])
  ]
}

export const getComboSubCardBtnText = (state: INewCompareState, nuxtApp?) => {
  const btnDict = {
    [INewCompareState.NoSub]: useTranslateI18n("payment.topCard[1].btn[0]", [], nuxtApp),
    [INewCompareState.CurrentSub]: useTranslateI18n("payment.topCard[1].btn[2]", [], nuxtApp),
    [INewCompareState.Upgrade]: useTranslateI18n("payment.topCard[1].btn[1]", [], nuxtApp),
    [INewCompareState.DownUpgrade]: useTranslateI18n("payment.topCard[1].btn[3]", [], nuxtApp)
  }

  return btnDict[state]
}

export const getComboSubCardBtnText2 = (state: SubStatus, nuxtApp?) => {
  const btnDict = {
    [SubStatus.AwaitCancelled]: useTranslateI18n("payment.topCard[1].btn[6]", [], nuxtApp),
    [SubStatus.AwaitDownGrade]: useTranslateI18n("payment.topCard[1].btn[5]", [], nuxtApp),
    [SubStatus.Failed]: useTranslateI18n("payment.topCard[1].btn[4]", [], nuxtApp),
    [SubStatus.Failed3]: useTranslateI18n("payment.topCard[1].btn[4]", [], nuxtApp),
    [SubStatus.Failed4]: useTranslateI18n("payment.topCard[1].btn[0]", [], nuxtApp)
  }

  return btnDict[state]
}

// 获取订阅成功，升降级成功，购买成功，reactivated成功的购买成功页面文案
export enum ISuccessResultType {
  SubType = 1,
  DownType,
  UpType,
  PksType,
  Reactivated
}

// 获取升降级失败，购买失败，订阅失败的页面文案
export enum IErrorSubResultType {
  SubType = 1,
  DownType,
  UpType,
  PksType
}

export const getNewSubResultDetail = (options: { type: ISuccessResultType; name: string; next_date?: string }) => {
  const dict = {
    [ISuccessResultType.SubType]: {
      title: useTranslateI18n("payment.comboSub.t56", [{ type: "static", class: "bg-d" }]),
      desc: useTranslateI18n("payment.comboSub.t55", [options.name])
    },
    [ISuccessResultType.UpType]: {
      title: useTranslateI18n("payment.comboSub.t58", [{ type: "static", class: "bg-d" }]),
      desc: useTranslateI18n("payment.comboSub.t57", [options.name])
    },
    [ISuccessResultType.DownType]: {
      title: useTranslateI18n("payment.comboSub.t59", [{ type: "static", class: "bg-d" }]),
      desc: useTranslateI18n("payment.comboSub.t60", [options.name, options.next_date])
    },
    [ISuccessResultType.PksType]: {
      title: useTranslateI18n("payment.comboSub.t61", [{ type: "static", class: "bg-d" }]),
      desc: useTranslateI18n("payment.comboSub.t62", [options.name])
    },
    [ISuccessResultType.Reactivated]: {
      title: useTranslateI18n("payment.comboSub.t63", [{ type: "static", class: "bg-d" }]),
      desc: useTranslateI18n("payment.comboSub.t64", [options.name])
    }
  }
  return dict[options.type]
}

export const getNewSubErrorResultDetail = (options: { type: IErrorSubResultType }) => {
  const dict = {
    [IErrorSubResultType.SubType]: {
      title: useTranslateI18n("payment.successDetail.errorSub.title.t1", [{ type: "static", class: "bg-d" }]),
      desc: useTranslateI18n("payment.successDetail.errorSub.desc")
    },
    [IErrorSubResultType.UpType]: {
      title: useTranslateI18n("payment.successDetail.upgradeFailed.title.t1", [{ type: "static", class: "bg-d" }]),
      desc: useTranslateI18n("payment.successDetail.errorSub.desc")
    },
    [IErrorSubResultType.DownType]: {
      title: useTranslateI18n("payment.successDetail.downgradeFailed.title.t1", [{ type: "static", class: "bg-d" }]),
      desc: useTranslateI18n("payment.successDetail.errorSub.desc")
    },
    [IErrorSubResultType.PksType]: {
      title: useTranslateI18n("payment.successDetail.purchaseFailed.title.t1", [{ type: "static", class: "bg-d" }]),
      desc: useTranslateI18n("payment.successDetail.errorSub.desc")
    }
  }
  return dict[options.type]
}
