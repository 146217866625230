import shareSalesInstance from "@/utils/sharesale"

export function usePaymentSs() {
  const _pointShareSalesPayment = (value, type, order_id?) => {
    shareSalesInstance.setEvent(value, type, order_id)
  }
  return {
    _pointShareSalesPayment
  }
}
