import ContactUsDialog from "~~/components/common/ContactUsDialog.vue"
import { createVNode, render } from "vue"

export const useContactUsDialog = () => {
  // 挂载  因为dialog是teleport 无需append to body
  const vm = createVNode(ContactUsDialog, {})
  const { vueApp } = useNuxtApp()
  vm.appContext = vueApp._context
  render(vm, document.createElement("div"))
}
