import { ICarouselListData } from "@/types/components/pages/combo/comboCarousel"
import { gradientList, Payment_Method, showMoreInfoCredits, showMoreInfoCountry } from "@/constant/pages/combo"
import { useUser } from "@/store/user"
import { ILocaleValue } from "@/constant/i18n-country"
import { ICardDetailData, IComboCardType } from "@/types/components/pages/combo/comboCardT"
import {
  IComboProductListObj,
  IPromotionComboState,
  IUserPaymentType,
  IUserPaymentMethodObj,
  IUserPaymentPlatform,
  IINewComboProductListObjData
} from "@/composables/api/combo"
import { IOrderInfo } from "@/types/components/pages/payment/paymentVnDialog"
import {
  PAYMENT_RESULT,
  LANDING_COMBO_BF,
  LANDING_COMBO_BF_GIFT,
  LANDING_COMBO_MUL_SPEC,
  LANDING_COMBO_MUL_XS,
  LANDING_PROMOTION,
  PAYMENT_COMBO_SUB,
  PAYMENT_PAGE_PATH
} from "@/constant/route"
import { ICardDetailData as ICardDetailDataObj } from "@/types/pages/landingCombo"
import type { ICusProductObj } from "@/types/pages/landingCusCombo/index"
import { getLocalDateString } from "~~/utils/date"
import { getJumpToken, SubStatus } from "@/composables/api/common"
import { useTranslateI18n } from "./store/i18n-country"
import { IPaymentResultType } from "~~/types/components/pages/payment/successDetail"
import { InviteActInfo } from "@/composables/api/welfare"
import { InviteActivityStatus } from "@/constant/pages/welfare"
import { usePurchaseReferer } from "@/composables/store/referrer"
import { LocalStorageKey } from "@/constant/cache"
import { SessStorageKey } from "~~/constant/cache"
import { redirectWithUrl } from "@/utils/utils"
import {
  getComboSubCardBtnText,
  getComboSubCardBtnText2,
  ISuccessResultType,
  IErrorSubResultType,
  getComboCardProductsData
} from "@/constant/pages/comboSub/index"

import { ISuccessType } from "@/types/components/pages/comboSub"

import { switchListType } from "@/constant/pages/payment"

// 短链接判断是否是landing_combo_mul_xs页面，购买结果需要显示发送邮箱
export const useIsLandingComboMulXs = () => useState("use_is_landing_combo_mul_xs", () => false)

// 端链接判断是否是landing_promotion夏促也没
export const useIsLandingPromotion = () => useState("use_is_landing_promotion", () => false)

// 判断是否客户端内嵌页面，如果是的话，不显示一些模块
export const useClientPage = () => {
  const isClientInlinePage = computed(() => {
    const { $bridge } = useNuxtApp()
    return !!($bridge && Object.keys($bridge).length)
  })
  return {
    isClientInlinePage
  }
}

// 记录未登录情况下点击的套餐id
export const useComboRecordLocal = () => {
  const setComboIdLocal = (id: any) => {
    setLocalStorage(LocalStorageKey.PAYMENT_PRODUCT_ID, id)
  }
  const removeComboIdLocal = () => {
    removeLocalStorage(LocalStorageKey.PAYMENT_PRODUCT_ID)
  }
  const comboIdLocalAndHandler = (productList: any, callback: (...args: any[]) => any) => {
    if (process.client) {
      const _product = Array.isArray(productList)
        ? productList?.find((item) => item.id === Number(getLocalStorage(LocalStorageKey.PAYMENT_PRODUCT_ID)))
        : productList.id === Number(getLocalStorage(LocalStorageKey.PAYMENT_PRODUCT_ID)) && productList
      removeComboIdLocal()
      callback && callback(_product)
    }
  }

  return {
    setComboIdLocal,
    comboIdLocalAndHandler
  }
}

// 套餐支付弹窗 和 paddle支付弹窗 和 支付成功失败
export function useCommonComboPayment(loadingClass = "payment-combo_loading_page") {
  // 获取当前支付方式设置
  const getCurrentAllPaymentContents = async (
    options: { custom_link_uuid?: string; alink_uuid?: string; product_id?: number } = {}
  ) => {
    const data = await postPaymentMethodAll(options)
    pm_config.value = []
    paddle_enable.value = false
    payssion_enable.value = false
    if (data && data.length) {
      data.map((pay) => {
        if (pay.type === IUserPaymentType.PaddleCard) {
          paddle_enable.value = true
        } else if (pay.type === IUserPaymentType.LocaleCard) {
          payssion_enable.value = true
          pm_config.value.push(pay)
        }
      })
    }
  }

  const use_user = useUser()
  const { download } = useDownload()
  const { deviceType } = useDevice()
  const { _pointPricePageTabItmeClick, _pointPopUpClick } = useStSdkCommonMp()
  const {
    _pointDTPAymentWebsitePurchasePage,
    _pointMpOfficialPricingButtonClick,
    _pointOfficialPricingBuyButtonClick,
    _pointOfficialH5BuyButtonClick,
    _pointDTOfficialPricingBuyButtonClick,
    _pointDTOfficialButtonClick
  } = usePaymentMP()

  const channelHistry = getLocalStorage(SessStorageKey.CHANNEL_HISTORY)

  const { isClientInlinePage } = useClientPage()

  // 套餐列表数据
  const comboProductListData = ref<ICarouselListData>([])

  // 套餐列表数据
  const comboProductList = ref<ICarouselListData>([])

  // 套餐列表底部文案
  const comboProductListDescs = ref<string[]>([])
  // 是否开启paddle支付
  const paddle_enable = ref(false)
  // 是否开启payssion支付
  const payssion_enable = ref(false)
  // passion支付配置
  const pm_config = ref<IUserPaymentMethodObj[]>([])
  // 套餐值区间
  const comboPriceRange = ref([])

  // 是否显示paddle弹窗
  const isShowPaddle = ref(false)
  // paddle弹窗数据
  const orderInfo: any = reactive({})
  // paddle标题
  const paddleTopTip = useTranslateI18n(`payment.comboPayment.paddleDialog.package`)
  // paddle金额单位
  const paddleUnit = useTranslateI18n(`payment.comboPayment.paddleDialog.unit`)

  // 落地页单个套餐数据
  const landingComboProduct = ref<ICardDetailDataObj | ICardDetailDataObj[]>({
    normalPrice: "",
    price: "",
    count: ``,
    singlePrice: "",
    btnText: useTranslateI18n(`payment.comboPayment.carousel.buyBtn`)
  })

  // 落地页新订阅数据
  const landingComboSubProduct = ref(getComboCardProductsData())

  // 落地页的自定义文案数据
  const landingCopyWrite = ref("")
  // 落地页的来源uuid
  const landingUuid = ref()
  // 落地页购买套餐按钮是否禁用点击
  const landingBuyButtonDisabled = ref(false)
  // 多个小套餐的自定义文案数据
  const landingTitleCopyWrite = ref("")

  // 新订阅的订阅套餐数据
  const newSubAllData = ref([])

  // 新订阅的订阅套餐原数据
  const newSubOriginAllData = ref([])

  // 新订阅的补充包和pay as you go数据
  const newSubPksAllData = ref([])

  const coverImage = ref("")
  const videoLink = ref("")

  // 判断是否是美国移动端购买页面进来的
  const isUsMobileComboRoute = computed(() => {
    return useRoute().path === unref(LANDING_COMBO_MUL_XS) || useIsLandingComboMulXs().value
  })

  // 判断是否是夏促落地页
  const isComboPromotionRoute = computed(() => {
    return useRoute().path === unref(LANDING_PROMOTION) || useIsLandingPromotion().value
  })

  // 判断落地页套餐的是新订阅模式 还是非订阅模式
  const landingComboMode = ref<ILandingProductType>(ILandingProductType.OldComboPks)

  // 判断是新订阅套餐模式
  const isNewSubMode = ref(false)

  // 判断订阅模式下的成功失败页面的提示类型
  const isNewSubDetailType = ref<ISuccessResultType | IErrorSubResultType>()

  const isSubPayAsYougo = computed(() => {
    return (
      [SubStatus.NoSub, SubStatus.Failed, SubStatus.Failed3, SubStatus.Failed4].includes(
        use_user.subscription_info_v2.status
      ) || !use_user.isLogin
    )
  })

  const isSubTopUpPack = computed(() => {
    return (
      use_user.isLogin &&
      [SubStatus.AwaitCancelled, SubStatus.AwaitDownGrade, SubStatus.Normal].includes(
        use_user.subscription_info_v2.status
      )
    )
  })

  // 判断夏促是否开始
  const isHotActivityStart = ref(false)

  // 美国套餐的截止时间戳
  const landingComboTimeStamp = ref(0)

  // 自定义套餐的单个套餐数据
  const cusComboProduct = ref<ICusProductObj>({
    count: "",
    price: "",
    singlePrice: "",
    device: ``,
    email: "",
    due: "",
    btnText: useTranslateI18n(`payment.comboPayment.carousel.buyBtn`)
  })
  // 自定义套餐的链接是否失效显示
  const cusComboLinkShow = ref(false)

  // 判断是否是自定义套餐进来的，不需要登录，直接购买
  const isCusComboAndNoLogin = ref(false)

  // 判断是否不需要登录的flag
  const isNoLoginFlag = computed(() => {
    return isCusComboAndNoLogin.value || useRoute().path === unref(LANDING_COMBO_MUL_SPEC)
  })

  // 判断是否是不需要登录的购买页面（兑换码）
  const isNoLoginComboSpec = computed(() => {
    return useRoute().path === unref(LANDING_COMBO_MUL_SPEC)
  })

  // 判断自定义套餐进来的，用户的product_type
  const cusComboProductType = ref()

  // 自定义套餐uuid
  const cusComboUuid = ref()

  // 自定义套餐编号
  const cusComboQuotation = ref()

  // 自定义套餐公司名称
  const cusComboCompany = ref()

  // 自定义套餐下发的用户email
  const cusComboEmail = ref()

  // 自定义套餐下发的用户注册国家
  const cusComboRegistryCountry = ref()

  // 自定义套餐下发类型
  const cusComboType = ref()

  // 下载落地页套餐数据
  const landingDownloadProduct = ref<ICardDetailData>({} as ICardDetailData)

  // 黑五落地页套餐数据
  const landingComboBfProducts = ref<ICardDetailData[] | ICardDetailData>()

  // 黑五落地页的活动信息
  const landingComboBfActivity = reactive<
    Pick<InviteActInfo, "activity_status" | "start_timestamp" | "end_timestamp"> & { disabled: boolean }
  >({
    activity_status: null,
    start_timestamp: null,
    end_timestamp: null,
    disabled: false
  })

  // 黑五倒计时
  const landingComboTimeValue = computed(() => {
    return landingComboBfActivity.activity_status === InviteActivityStatus.InProgress
      ? landingComboBfActivity.end_timestamp
      : undefined
  })

  // 黑五单个套餐填写的用户信息
  const landingComboUserInfo = ref({}) as any

  // 是否是黑五多个套餐
  const is_sec_kill_activity = computed(() => {
    return useRoute().path === unref(LANDING_COMBO_BF)
  })

  enum ProductKey {
    Start = "Start",
    Contact = "Contact"
  }

  // 页面加载loading
  const { loadingRef, openLoading, closeLoading } = useLoadingHooks({ customClass: loadingClass })

  function setPmConfig(config) {
    const resultRadioList = []
    for (const paymentRadio in config) {
      resultRadioList.push(paymentRadio)
    }
    return resultRadioList
  }

  //  判断单位是否放后面
  const isBackUnit = ref(false)

  // currency单位，上报ads埋点需要
  const currencyType = ref(ICurrency.USD)

  // 获取当前套餐的 单位是否前置，设置currency
  const getIsProductIsBack = (product: IComboProduct | IComboProductListObj) => {
    if (product) {
      if (Array.isArray(product)) {
        currencyType.value = product[0]?.currency
        return !product[0]?.currency_char_prefix
      } else {
        currencyType.value = product?.currency
        return !product?.currency_char_prefix
      }
    } else {
      return false
    }
  }

  // 判断是否是日本
  const isJp = computed(() => {
    if (process.client) {
      if (isCusComboAndNoLogin.value) {
        return cusComboProductType.value === ProductType.JP_PKG
      }
      if (use_user.isLogin) {
        return use_user.productType === ProductType.JP_PKG
      } else {
        return useI18nCountry().value === ILocaleValue.ja
      }
    }
  })

  function setPricingProduct(_product: any, i, numberTranslateFn = useNumFormatByK, nuxtApp?: any) {
    let resultProduct = {} as ICardDetailData

    isBackUnit.value = getIsProductIsBack(_product)

    // 黑五落地页的card样式
    resultProduct.extraCredits = useTranslateI18n(
      `landing.landingComboBf.extraPrice`,
      [
        { text: useNumFormat(_product.complimentary_credits), class: "extra-credits" },
        {
          type: "static",
          class: "t-flag"
        }
      ],
      nuxtApp
    )

    // 多个小套餐落地页的card样式
    resultProduct.extraCredits1 = _product.free_credits
      ? useTranslateI18n(
          `landing.landingComboMul.extra`,
          [{ text: useNumFormat(_product.free_credits) }, { type: "static", class: "t-flag" }],
          nuxtApp
        )
      : ""
    resultProduct.extraValidate = _product.free_valid_day
      ? useTranslateI18n(
          `landing.landingComboMul.validate`,
          [{ type: "static", class: "t-flag" }, _product.free_valid_day],
          nuxtApp
        )
      : ""
    resultProduct.priceFlag = unref(isBackUnit)
    resultProduct.save = _product.discount_percentage ? `${_product.discount_percentage}%` : ``
    resultProduct.type = _product.most_popular ? IComboCardType.Popular : IComboCardType.Normal
    resultProduct.btnText = _product.buy_now
      ? useTranslateI18n(`payment.comboPayment.carousel.buyBtn`, [], nuxtApp)
      : useTranslateI18n(`payment.comboPayment.carousel.contact`, [], nuxtApp)
    resultProduct.count = `${numberTranslateFn(_product.credits)} ${useTranslateI18n(
      `payment.comboPayment.carousel.unit`,
      [],
      nuxtApp
    )}`
    // 日本单位要放到后面
    resultProduct.normalPrice = _product.origin_price
      ? unref(isBackUnit)
        ? _product.origin_price + _product.currency_char
        : _product.currency_char + _product.origin_price
      : undefined
    resultProduct.price = unref(isBackUnit)
      ? _product.total_price + _product.currency_char
      : _product.currency_char + _product.total_price
    // 日本单位要放到后面
    resultProduct.singlePrice = unref(isBackUnit)
      ? _product.per_credit_price + _product.currency_char
      : _product.currency_char + _product.per_credit_price
    resultProduct.device = `2 ${useTranslateI18n(`payment.comboPayment.carousel.devices`, [], nuxtApp)}`
    resultProduct.pkgName1 = `${getSplitPkgname(_product.name)[0]}-`
    resultProduct.pkgName2 = `${getSplitPkgname(_product.name)[1]} ${useTranslateI18n(
      `payment.comboPayment.carousel.unit`,
      [],
      nuxtApp
    )}`
    resultProduct.pkgUnit = _product.currency_char
    resultProduct.popular = !!_product.most_popular
    resultProduct.isBackUnit = !!unref(isBackUnit)
    resultProduct.wrapperStyle = {
      background: i >= gradientList.length ? gradientList[i % gradientList.length].bg : gradientList[i].bg
    }

    resultProduct.titleStyle = {
      color: i >= gradientList.length ? gradientList[i % gradientList.length].textStyle : gradientList[i].textStyle
    }

    resultProduct.u_discount_per_credit_price = _product.discount_per_credit_price
      ? unref(isBackUnit)
        ? _product.discount_per_credit_price + _product.currency_char
        : _product.currency_char + _product.discount_per_credit_price
      : undefined

    resultProduct.u_discount_price = _product.discount_price
      ? unref(isBackUnit)
        ? _product.discount_price + _product.currency_char
        : _product.currency_char + _product.discount_price
      : undefined

    resultProduct.u_discount_amount = _product.discount_amount
      ? unref(isBackUnit)
        ? _product.discount_amount + _product.currency_char
        : _product.currency_char + _product.discount_amount
      : undefined

    //套餐过期时间-》转换成 年、月
    if (_product.duration_seconds) {
      const { years, months } = convertSeconds(_product.duration_seconds)
      resultProduct.validText = years
        ? years === 1
          ? useTranslateI18n("landing.landingComboMulXs.valid3", [], nuxtApp)
          : useTranslateI18n("landing.landingComboMulXs.valid2", [years], nuxtApp)
        : months === 1
        ? useTranslateI18n("landing.landingComboMulXs.valid4", [], nuxtApp)
        : useTranslateI18n("landing.landingComboMulXs.valid1", [months], nuxtApp)
    }

    resultProduct = { ...resultProduct, ..._product }

    return resultProduct
  }

  // 获取套餐列表
  // 获取套餐列表
  async function initComboProductData() {
    const nuxtApp = useNuxtApp()

    const jp = unref(isJp)

    const { product = [], price_range } = await getComboProductList()

    comboPriceRange.value = price_range || []
    let productList = []
    comboProductListData.value = productList = product.map((_product, i) => {
      return setPricingProduct(_product, i, useNumFormatByK, nuxtApp)
    })

    comboProductList.value = productList

    comboProductListData.value = [
      {
        key: ProductKey.Start,
        priceFlag: unref(isBackUnit),
        // wrapperStyle: {
        //   background: gradientList[0]
        // },
        count: `5 ${useTranslateI18n(`payment.comboPayment.carousel.unit`, [], nuxtApp)}`,
        customDesc: useTranslateI18n(`payment.comboPayment.carousel.f`, [], nuxtApp),
        price: unref(isBackUnit) ? `0` : getUSCharAndPrice("$", 0),
        btnText: useTranslateI18n(`payment.comboPayment.carousel.getStarted`, [], nuxtApp),
        device: `1 ${useTranslateI18n(`payment.comboPayment.carousel.devices`, [], nuxtApp)}`,
        descs: [
          useTranslateI18n(`payment.comboPayment.carousel.desc1[0]`, [], nuxtApp),
          useTranslateI18n(`payment.comboPayment.carousel.desc1[1]`, [], nuxtApp)
        ]
      },
      ...comboProductListData.value,
      {
        key: ProductKey.Contact,
        priceFlagStyle: !!unref(isBackUnit), // 自定义文字高度设置
        wrapperStyle: {
          background: gradientList[gradientList.length - 1].bg
        },
        titleStyle: {
          color: gradientList[gradientList.length - 1].textStyle
        },
        type: IComboCardType.Contact,
        count: useTranslateI18n(`payment.comboPayment.carousel.customCredits`, [], nuxtApp),
        customDesc: useTranslateI18n(`payment.comboPayment.carousel.talk`, [], nuxtApp),
        btnText: useTranslateI18n(`payment.comboPayment.carousel.contact`, [], nuxtApp),
        device: useTranslateI18n(`payment.comboPayment.carousel.customDevices`, [], nuxtApp),
        descs: [useTranslateI18n(`payment.comboPayment.carousel.desc2[0]`, [], nuxtApp)]
      }
    ]
    comboProductListDescs.value = [
      useTranslateI18n(`payment.comboPayment.carousel.bDescs[0]`, [], nuxtApp),
      useTranslateI18n(`payment.comboPayment.carousel.bDescs[1]`, [], nuxtApp),
      useTranslateI18n(`payment.comboPayment.carousel.bDescs[2]`, [], nuxtApp)
    ]
    jp && comboProductListDescs.value.push(useTranslateI18n(`payment.comboPayment.carousel.bDescs[3]`, [], nuxtApp))
    // 客户端内嵌不显示
    // if (isClientInlinePage.value) {
    //   comboProductListData.value.splice(0, 1)
    //   comboProductListData.value.splice(comboProductListData.value.length - 1, 1)
    // }
    // 判断本地缓存过用户点击的，有的话自动弹窗
    const { comboIdLocalAndHandler } = useComboRecordLocal()
    comboIdLocalAndHandler(productList, async (data) => {
      if (use_user.isLogin && !use_user.isChildUserAndBusinessUser) {
        if (data && data.buy_now !== false) {
          await executePaymentDialog(data)
        }
        await nextTick(() => {})
        useShowSignUpSuccessDialog()
      }
    })
    return productList as ICarouselListData
  }

  // 新订阅-获取订阅列表
  async function initNewComboSubProduct(extraProduct?: IINewComboProductListObjData, nuxt?: any) {
    const nuxtApp = nuxt || useNuxtApp()

    const status = use_user.subscription_info_v2.status

    isNewSubMode.value = true

    let btnText

    if (use_user.isLogin) {
      btnText = getComboSubCardBtnText2(status, nuxtApp)
    }

    let product

    let metas

    if (!extraProduct) {
      const { data, meta } = await getNewAllSubComboData()
      product = data
      metas = meta
    }

    const productList = (extraProduct || product)
      .map((_product, i) => {
        const result = setPricingProduct(_product, i, useNumFormat, nuxtApp)
        return {
          ...result,
          btnText: btnText || getComboSubCardBtnText(result.compare_state, nuxtApp)
        }
      })
      .map((_product, i) => {
        let btnText = _product.btnText
        // 兼容 落地页的降级以及 落地页的 当前plan 按钮btnText
        if (landingComboMode.value === ILandingProductType.NewComboSub) {
          if (
            _product.compare_state === INewCompareState.CurrentSub ||
            _product.compare_state === INewCompareState.DownUpgrade ||
            use_user.subscription_info_v2.status === SubStatus.AwaitCancelled ||
            use_user.subscription_info_v2.status === SubStatus.AwaitDownGrade ||
            [SubStatus.Failed3, SubStatus.Failed].includes(use_user.subscription_info_v2.status)
          ) {
            btnText = useTranslateI18n("landing.landingComboMulXs.more", [], nuxtApp)
          }
        }
        return {
          ..._product,
          btnText
        }
      })

    newSubOriginAllData.value = productList

    newSubAllData.value = productList.filter((_p) => _p.show)

    const { comboIdLocalAndHandler } = useComboRecordLocal()
    comboIdLocalAndHandler(productList, async (data) => {
      if (use_user.isLogin && !use_user.isChildUserAndBusinessUser) {
        if (data && data.buy_now !== false) {
          await executePaymentDialog(data)
        }
        await nextTick(() => {})
        useShowSignUpSuccessDialog()
      }
    })
    return {
      productList: productList as (ICardDetailData & INewComboProductListObj)[],
      time_left: metas?.time_left
    }
  }

  // 新订阅-获取pay as you go 或者 补充包
  async function initNewComboSubPacksProduct(extraProduct?: IINewComboProductListObjData, nuxt?: any) {
    const nuxtApp = nuxt || useNuxtApp()

    isNewSubMode.value = true

    let product

    if (!extraProduct) {
      product = await getNewAllSubPacksData()
    } else {
      product = extraProduct
    }

    const productList = product.map((_product, i) => {
      const result = setPricingProduct(_product, i, useNumFormat, nuxtApp)
      return {
        ...result,
        btnText: useTranslateI18n("payment.topCard[1].btn[0]", [], nuxtApp)
      }
    })

    newSubPksAllData.value = productList

    const { comboIdLocalAndHandler } = useComboRecordLocal()
    comboIdLocalAndHandler(productList, async (data) => {
      if (use_user.isLogin && !use_user.isChildUserAndBusinessUser) {
        if (data && data.buy_now !== false) {
          await executePaymentDialog(data)
        }
        await nextTick(() => {})
        useShowSignUpSuccessDialog()
      }
    })
    return productList as (ICardDetailData & INewComboProductListObj)[]
  }

  // 初始化套餐落地页数据
  async function initLandingComboProduct() {
    const { sId, _uuid } = useGetLandingShotParams()
    if (!sId || !_uuid) {
      landingBuyButtonDisabled.value = !sId || !_uuid
      return useErrorMessage(useTranslateI18n("landing.alert.title"))
    }
    // 判断用户已经登录的情况 给个消息提示
    if (use_user.isLogin) {
      useSuccessMessage({ message: useTranslateI18n(`landing.landingCombo.loginIn`), duration: 4000 })
    }
    await getLandingComboProductFn(_uuid as string)
  }

  let setComboSubIndexAndPacksDesc = false

  const useSetComboSubDefault = (options: {
    product: IINewComboProductListObjData
    product_additional: IINewComboProductListObjData
    nuxt: any
  }) => {
    const nuxtApp = options.nuxt
    initNewComboSubProduct(options.product as unknown as IINewComboProductListObjData, nuxtApp)
    initNewComboSubPacksProduct(options.product_additional as unknown as IINewComboProductListObjData, nuxtApp)

    landingComboSubProduct.value[1].topConfig.productCardList = newSubAllData.value as (ICardDetailData &
      INewComboProductListObj)[]

    landingComboSubProduct.value[0].topConfig.productCardList = newSubPksAllData.value as (ICardDetailData &
      INewComboProductListObj)[]

    function setIndexAndPacksCardDesc(pksProductListIndex?: number, subsProductListIndex?: number) {
      const isDefaultProductIndex = isUndefined(pksProductListIndex) && isUndefined(subsProductListIndex)
      const pksProductList = landingComboSubProduct.value[0].topConfig.productCardList || []
      const subProductList = landingComboSubProduct.value[1].topConfig.productCardList || []
      if (unref(isSubPayAsYougo)) {
        if (pksProductList && pksProductList.length >= 3 && subProductList && subProductList.length >= 3) {
          const pks_index = isDefaultProductIndex ? 2 : pksProductListIndex
          const subs_index = isDefaultProductIndex ? 2 : subsProductListIndex

          landingComboSubProduct.value[0].selectIndex = pks_index
          landingComboSubProduct.value[1].selectIndex = subs_index

          const { years } = convertSeconds(pksProductList[pks_index].duration_seconds)

          // 设置pay as you go文案
          landingComboSubProduct.value[0].bottomConfig.item1.textList[0].list = [
            useTranslateI18n(
              "payment.upgradeDialog.credits",
              [useNumFormat(pksProductList[pks_index].credits)],
              nuxtApp
            ),
            pksProductList[pks_index].device_limit === 1
              ? useTranslateI18n("payment.comboSub.t72", [], nuxtApp)
              : useTranslateI18n("payment.comboSub.t2", [pksProductList[pks_index].device_limit], nuxtApp),
            useTranslateI18n("payment.comboSub.t3", [years], nuxtApp)
          ]

          // 设置订阅文案
          landingComboSubProduct.value[1].bottomConfig.item1.textList[0].list[0] = useTranslateI18n(
            "payment.upgradeDialog.credits",
            [useNumFormat(subProductList[subs_index].credits)],
            nuxtApp
          )
          landingComboSubProduct.value[1].bottomConfig.item1.textList[0].list[1] =
            subProductList[subs_index].device_limit === 1
              ? useTranslateI18n("payment.comboSub.t72", [], nuxtApp)
              : useTranslateI18n(
                  "payment.comboSub.t2",
                  [useNumFormat(subProductList[subs_index].device_limit)],
                  nuxtApp
                )

          landingComboSubProduct.value[1].bottomConfig.item1.textList[0].list[4] =
            subProductList[subs_index].credits >= 9000 ? useTranslateI18n("payment.comboSub.t7", [], nuxtApp) : ""

          landingComboSubProduct.value[1].bottomConfig.item1.textList[0].list[5] =
            subProductList[subs_index].credits > 9000 ? useTranslateI18n("payment.comboSub.t73", [], nuxtApp) : ""
        } else if (subProductList && subProductList.length) {
          const pks_index = isDefaultProductIndex ? 0 : pksProductListIndex
          const subs_index = isDefaultProductIndex ? 0 : subsProductListIndex

          landingComboSubProduct.value[0].selectIndex = pks_index
          landingComboSubProduct.value[1].selectIndex = subs_index
          const { years } = convertSeconds(pksProductList[pks_index]?.duration_seconds)
          landingComboSubProduct.value[0].bottomConfig.item1.textList[0].list = [
            useTranslateI18n(
              "payment.upgradeDialog.credits",
              [useNumFormat(pksProductList[pks_index]?.credits)],
              nuxtApp
            ),
            pksProductList[pks_index]?.device_limit === 1
              ? useTranslateI18n("payment.comboSub.t72", [], nuxtApp)
              : useTranslateI18n("payment.comboSub.t2", [pksProductList[pks_index]?.device_limit], nuxtApp),
            useTranslateI18n("payment.comboSub.t3", [years], nuxtApp)
          ]

          // 设置订阅文案
          landingComboSubProduct.value[1].bottomConfig.item1.textList[0].list[0] = useTranslateI18n(
            "payment.upgradeDialog.credits",
            [useNumFormat(subProductList[subs_index].credits)],
            nuxtApp
          )
          landingComboSubProduct.value[1].bottomConfig.item1.textList[0].list[1] =
            subProductList[subs_index].device_limit === 1
              ? useTranslateI18n("payment.comboSub.t72", [], nuxtApp)
              : useTranslateI18n(
                  "payment.comboSub.t2",
                  [useNumFormat(subProductList[subs_index].device_limit)],
                  nuxtApp
                )

          landingComboSubProduct.value[1].bottomConfig.item1.textList[0].list[4] =
            subProductList[subs_index].credits >= 9000 ? useTranslateI18n("payment.comboSub.t7", [], nuxtApp) : ""

          landingComboSubProduct.value[1].bottomConfig.item1.textList[0].list[5] =
            subProductList[subs_index].credits > 9000 ? useTranslateI18n("payment.comboSub.t73", [], nuxtApp) : ""
        }
      } else {
        // 正常订阅用户
        if (subProductList && subProductList.length) {
          const currentNextComboIndex =
            subProductList.findIndex((item) => {
              return item.compare_state === INewCompareState.CurrentSub
            }) + 1
          const subs_index = isDefaultProductIndex
            ? currentNextComboIndex >= subProductList.length
              ? subProductList.length - 1
              : currentNextComboIndex
            : subsProductListIndex

          const pks_index = isDefaultProductIndex
            ? pksProductList.length >= subs_index + 1
              ? subs_index
              : pksProductList.length - 1
            : pksProductListIndex

          // 设置订阅和补充包索引
          landingComboSubProduct.value[1].selectIndex = subs_index
          landingComboSubProduct.value[0].selectIndex = pks_index
          // 设置补充包文案
          landingComboSubProduct.value[0].topConfig.topText.title1 = useTranslateI18n(
            "payment.comboSub.t48",
            [],
            nuxtApp
          )
          landingComboSubProduct.value[0].topConfig.topText.desc1 = useTranslateI18n(
            "payment.comboSub.t49",
            [],
            nuxtApp
          )
          landingComboSubProduct.value[0].bottomConfig.item1.textList[0].list = [
            useTranslateI18n(
              "payment.buyDataPkgDialog.credits",
              [useNumFormat(pksProductList[pks_index]?.credits)],
              nuxtApp
            ),
            useTranslateI18n("payment.comboSub.t25", [], nuxtApp),
            useTranslateI18n("payment.comboSub.t26", [], nuxtApp)
          ]
          // 订阅card文案
          // landingComboSubProduct.value[1].topConfig.topText.desc1 = useTranslateI18n("payment.comboSub.t49", [], nuxtApp)
          landingComboSubProduct.value[1].bottomConfig.item1.textList[0].list[0] = useTranslateI18n(
            "payment.upgradeDialog.credits",
            [useNumFormat(subProductList[subs_index].credits)],
            nuxtApp
          )
          landingComboSubProduct.value[1].bottomConfig.item1.textList[0].list[1] =
            subProductList[subs_index].device_limit === 1
              ? useTranslateI18n("payment.comboSub.t72", [], nuxtApp)
              : useTranslateI18n(
                  "payment.comboSub.t2",
                  [useNumFormat(subProductList[subs_index].device_limit)],
                  nuxtApp
                )

          landingComboSubProduct.value[1].bottomConfig.item1.textList[0].list[4] =
            subProductList[subs_index].credits >= 9000 ? useTranslateI18n("payment.comboSub.t7", [], nuxtApp) : ""

          landingComboSubProduct.value[1].bottomConfig.item1.textList[0].list[5] =
            subProductList[subs_index].credits > 9000 ? useTranslateI18n("payment.comboSub.t73", [], nuxtApp) : ""
        }
      }
    }

    watch(
      () => [landingComboSubProduct.value[0].selectIndex, landingComboSubProduct.value[1].selectIndex],
      ([pksProductListIndex, subsProductListIndex]) => {
        setIndexAndPacksCardDesc(pksProductListIndex, subsProductListIndex)
      }
    )

    !setComboSubIndexAndPacksDesc && setIndexAndPacksCardDesc()

    setComboSubIndexAndPacksDesc = true
  }

  // 获取落地页套餐数据
  // 未登录请求判断链接是否过期，登录判断是否符合购买地区，是否只支持首购
  const getLandingComboProductFn = async (_uuid: string) => {
    const isHotPromotionRoute = isComboPromotionRoute.value
    const nuxtApp = useNuxtApp()
    try {
      const {
        product,
        product_additional,
        product_type,
        copywriter,
        tag,
        title_copywriter,
        buy_count,
        timestamp,
        start_timestamp,
        time_state,
        cover_image,
        video_link
      } = await getLandingComboProduct(_uuid as string)

      // 判断 订阅模式 还是 套餐模式
      landingComboMode.value = product_type

      isBackUnit.value = getIsProductIsBack(product)

      // 判断是否夏促
      if (isHotPromotionRoute) {
        switch (time_state) {
          case IPromotionComboState.IsEnd:
            isHotActivityStart.value = false
            landingComboTimeStamp.value = timestamp
            break
          case IPromotionComboState.Inprogress:
            isHotActivityStart.value = false
            landingComboTimeStamp.value = timestamp
            break
          case IPromotionComboState.NoStart:
            isHotActivityStart.value = true
            landingComboTimeStamp.value = start_timestamp
            break
        }
      } else {
        landingComboTimeStamp.value = timestamp
      }

      // 夏促落地页结束
      if (isHotPromotionRoute) {
        if (time_state === IPromotionComboState.IsEnd) {
          return useLandingPromotionEndDialog()
        }
      } else {
        if (tag) {
          landingBuyButtonDisabled.value = true
          useLandingComboTipDialog({
            type: tag
          })
          return false
        }
      }

      // 记录来源 后续paddle支付 和 passion支付需要传给后端
      landingUuid.value = _uuid
      landingCopyWrite.value = copywriter
      landingTitleCopyWrite.value = title_copywriter
      coverImage.value = cover_image
      videoLink.value = video_link
      // 多个小套餐
      if (Array.isArray(product) && product && product_type === ILandingProductType.OldComboPks) {
        landingComboProduct.value = product
          .map((_product, i) => setPricingProduct(_product, i, useNumFormat, nuxtApp))
          .map((_p) => {
            return {
              ..._p,
              btnText: useTranslateI18n(`payment.comboPayment.carousel.buyBtn`, [], nuxtApp)
            }
          })
        // 单个小套餐
      } else if (product && product_type === ILandingProductType.OldComboPks) {
        landingComboProduct.value = {
          normalPrice: product.origin_price
            ? unref(isBackUnit)
              ? product.origin_price + product.currency_char
              : product.currency_char + product.origin_price
            : undefined,
          price: unref(isBackUnit)
            ? product.total_price + product.currency_char
            : product.currency_char + product.total_price,
          count: `/${useNumFormatByK(product.credits)} ${useTranslateI18n(
            `payment.comboPayment.carousel.unit`,
            [],
            nuxtApp
          )}`,
          singlePrice: unref(isBackUnit)
            ? product.per_credit_price + product.currency_char
            : product.currency_char + product.per_credit_price,
          btnText: useTranslateI18n(`payment.comboPayment.carousel.buyBtn`, [], nuxtApp),
          buyCount: buy_count ? buy_count : 0,
          ...product
        }
        // 订阅模式
      } else if (product_type === ILandingProductType.NewComboSub) {
        // 走新订阅逻辑
        useSetComboSubDefault({
          nuxt: nuxtApp,
          product: product as IINewComboProductListObjData,
          product_additional: product_additional as IINewComboProductListObjData
        })

        return true
      }

      // 判断本地缓存过用户点击的，有的话自动弹窗
      const { comboIdLocalAndHandler } = useComboRecordLocal()
      comboIdLocalAndHandler(landingComboProduct.value, async (data) => {
        if (use_user.isLogin && !use_user.isChildUserAndBusinessUser) {
          if (data && data.buy_now !== false) {
            await executePaymentDialog(data)
          }
          await nextTick(() => {})
          useShowSignUpSuccessDialog()
        }
      })

      return true
    } catch (e) {
      landingBuyButtonDisabled.value = true
      return false
    }
  }

  // 初始化 自定义购买链接的数据
  async function initCusComboProduct() {
    const { _uuid } = useRoute().query
    if (!_uuid) {
      cusComboLinkShow.value = true
      return
    }
    await getCusComboProduct(_uuid as string)
  }

  // 自定义购买链接数据获取
  async function getCusComboProduct(uuid: string) {
    try {
      const {
        paddle_enable: pd_enable,
        payssion_enable: pay_enable,
        pm_config: config,
        custom_link,
        product
      } = await getCusComboInfo(uuid)

      isBackUnit.value = getIsProductIsBack(product)

      // 标记不需要登录就可以购买了
      cusComboLinkShow.value = !custom_link.state
      if (!custom_link.state) {
        return false
      }
      cusComboType.value = custom_link.type
      cusComboRegistryCountry.value = custom_link.register_country
      cusComboQuotation.value = custom_link.quotation
      cusComboCompany.value = custom_link.company_name
      cusComboUuid.value = uuid
      cusComboProductType.value = custom_link.pay_mode
      isCusComboAndNoLogin.value = true
      cusComboEmail.value = custom_link.evoto_account
      cusComboProduct.value = {
        count: `${useNumFormatByK(product.credits)} ${useTranslateI18n(`payment.comboPayment.carousel.unit`)}`,
        price: unref(isBackUnit)
          ? product.total_price + product.currency_char
          : product.currency_char + product.total_price,
        singlePrice: unref(isBackUnit)
          ? product.per_credit_price + product.currency_char
          : product.currency_char + product.per_credit_price,
        device: custom_link.description,
        email: custom_link.evoto_account,
        due: getLocalDateString(custom_link.payment_due_by, "YYYY/MM/DD"),
        btnText: useTranslateI18n(`payment.comboPayment.carousel.buyBtn`),
        ...product
      }
      return true
    } catch (e) {
      cusComboLinkShow.value = true
      return false
    }
  }

  // 初始化 下载落地页的套餐
  async function initDownloadLandingComboProduct() {
    await getDownloadLandingCombo()
  }

  // 获取 下载落地页套餐数据
  async function getDownloadLandingCombo() {
    if (use_user.productType == ProductType.Sub) return
    const { product = {} } = await getLandingDownloadComboProducts()
    landingDownloadProduct.value = setPricingProduct(product, 2)
  }

  // 初始化黑五落地页数据
  async function initLandingComboBf(type = ActivityTypeEnum.SecKill) {
    // 判断用户已经登录的情况 给个消息提示
    if (use_user.isLogin && !isClientInlinePage.value) {
      useSuccessMessage({ message: useTranslateI18n(`landing.landingCombo.loginIn`), duration: 4000 })
    }
    await getLandingComboBfProducts(type)
  }

  async function getLandingComboBfProducts(type = ActivityTypeEnum.SecKill, isDialog = true) {
    const nuxtApp = useNuxtApp()
    try {
      const { product_list, product, activity_status, end_timestamp, start_timestamp, tag } = await fetchInviteActInfo2(
        type
      )
      landingComboBfProducts.value =
        type === ActivityTypeEnum.SecKill
          ? product_list && product_list.map((_product, i) => setPricingProduct(_product, i, useNumFormat, nuxtApp))
          : product && setPricingProduct(product, 0, useNumFormat, nuxtApp)
      landingComboBfActivity.activity_status = activity_status
      landingComboBfActivity.end_timestamp = end_timestamp
      landingComboBfActivity.start_timestamp = start_timestamp
      landingComboBfActivity.disabled = false
      if (!isDialog) {
        return
      }
      if (tag) {
        useLandingComboTipDialog({
          type: tag
        })
        landingComboBfActivity.disabled = true
        return false
      }
      // 过期或者活动还没开始的弹窗
      if (
        landingComboBfActivity.activity_status === InviteActivityStatus.Timeout ||
        landingComboBfActivity.activity_status === InviteActivityStatus.Pending
      ) {
        const timeOutOptionsDict = {
          [ActivityTypeEnum.SecKill]: {
            width: 573,
            showDesc: false
          },
          [ActivityTypeEnum.GiftCard]: {
            title: useTranslateI18n(`landing.landingComboBfGift.ended`, [], nuxtApp),
            width: 573
          }
        }
        const pendingOptionsDict = {
          [ActivityTypeEnum.SecKill]: {
            countDownTimeOutHandler() {
              getLandingComboBfProducts()
            },
            countDownTimestamp: start_timestamp,
            width: 510
          },
          [ActivityTypeEnum.GiftCard]: {
            title: useTranslateI18n(`landing.landingComboBfGift.coming`, [], nuxtApp),
            showCountDown: false
          }
        }
        // 过期弹窗
        landingComboBfActivity.activity_status === InviteActivityStatus.Timeout
          ? useLandingComboBfTimeoutDialog(timeOutOptionsDict[type])
          : useLandingComboBfPendingDialog(pendingOptionsDict[type])
        landingComboBfActivity.disabled = true
        return false
      }
      return true
    } catch (e) {
      landingComboBfActivity.disabled = true
      return false
    }
  }

  // 套餐卡片点击
  const cardBtnClick = useDebounceFn(
    async ({
      data,
      openLoading,
      closeLoading,
      landingBfConfig
    }: {
      data: ICardDetailData & IComboProductListObj
      openLoading: any
      closeLoading: any
      landingBfConfig?: {
        gift_card_receiver?: string
        sender_name?: string
      }
    }) => {
      // 落地页按钮禁用 不可点击
      if (landingBuyButtonDisabled.value || landingComboBfActivity.disabled) {
        return
      }
      openLoading && openLoading()
      // 黑五落地页秒杀套餐
      if (useRoute().path === unref(LANDING_COMBO_BF) || useRoute().path === unref(LANDING_COMBO_BF_GIFT)) {
        landingComboUserInfo.value = landingBfConfig || {}
        const resultFlag = await getLandingComboBfProducts(
          useRoute().path === unref(LANDING_COMBO_BF) ? ActivityTypeEnum.SecKill : ActivityTypeEnum.GiftCard
        )
        if (!resultFlag) {
          closeLoading && closeLoading()
          return
        }
      }
      // 如果是落地页的buy now点击，需要判断错误条件
      if (unref(landingUuid)) {
        const resultFlag = await getLandingComboProductFn(unref(landingUuid))
        if (!resultFlag) {
          closeLoading && closeLoading()
          return
        }
      }
      // 如果是自定义购买链接的buy now点击，需要判断错误条件
      if (unref(cusComboUuid)) {
        const resultFlag = await getCusComboProduct(unref(cusComboUuid))
        if (!resultFlag) {
          closeLoading && closeLoading()
          return
        }
      }
      if (data.key) {
        // 下载
        if (data.key === ProductKey.Start) {
          !unref(isClientInlinePage) &&
            _pointPricePageTabItmeClick({
              element_name: "get_started",
              element_type: "button",
              element_position: "hero_section"
            })
          download(deviceType.value)
        } else if (data.key === ProductKey.Contact) {
          // useEventBusEmit(EmitName.ShowContactUs, true)
          useContactUsDialog()
          !unref(isClientInlinePage) &&
            _pointPricePageTabItmeClick({
              element_name: "contact_sales",
              element_type: "button",
              element_position: "hero_section"
            })
          !unref(isClientInlinePage) &&
            _pointMpOfficialPricingButtonClick({
              buttonName: "contactSale",
              pageTitle: "pricingPage",
              featureModule: "officialPricing",
              featureName: "clickPricingPageButton"
            })
        }
        closeLoading && closeLoading()
        return
      }
      // 如果购买页面的data.buy_now字段为false 显示contact us
      if (data.buy_now === false) {
        useContactUsDialog()
        !unref(isClientInlinePage) &&
          _pointMpOfficialPricingButtonClick({
            buttonName: "contactSale",
            pageTitle: "pricingPage",
            featureModule: "officialPricing",
            featureName: "clickPricingPageButton"
          })
        // 客户端内嵌页 contact-us点击
        unref(isClientInlinePage) && _pointDTOfficialButtonClick()
        closeLoading && closeLoading()
        return
      }
      !unref(isClientInlinePage) &&
        _pointOfficialPricingBuyButtonClick({
          packageId: data.id,
          packageName: data.name,
          packagePrice: data.price,
          packageCredit: data.credits
        })
      !unref(isClientInlinePage) &&
        _pointOfficialH5BuyButtonClick({
          packageId: data.id,
          packageName: data.name,
          packagePrice: data.price,
          packageCredit: data.credits
        })
      !unref(isClientInlinePage) &&
        _pointPricePageTabItmeClick({
          element_name: `buy_now_${data.name}`,
          element_type: "button",
          element_position: "hero_section"
        })
      // 在客户端点击buy_now
      unref(isClientInlinePage) &&
        _pointDTOfficialPricingBuyButtonClick({
          packageId: data.id,
          packageName: data.name,
          packagePrice: data.price,
          packageCredit: data.credits
        })

      // // 没登录先登录
      if (!use_user.isLogin && !unref(isNoLoginFlag)) {
        const { setComboIdLocal } = useComboRecordLocal()
        setComboIdLocal(data.id)
        useWarningMessage(useTranslateI18n(`payment.logged`))
        use_user.showLoginDialogAction()
        closeLoading && closeLoading()
        return
      }
      // 子用户不让购买
      if (use_user.isChildUserAndBusinessUser && !unref(isNoLoginFlag)) {
        usePurchaseFailedDialog()
        closeLoading && closeLoading()
        return
      }

      // 支付方式选择 共存弹窗选择paddle或者payssion
      await executePaymentDialog(data)
      closeLoading && closeLoading()
    },
    500
  )

  const executePaymentDialog = async (
    data: (ICardDetailData & IComboProductListObj) | (ICardDetailData & INewComboProductListObj)
  ) => {
    const isNewSubPksClick = unref(isNewSubMode) && !Object.keys(data).includes("compare_state")

    const isNewSubClick = unref(isNewSubMode) && Object.keys(data).includes("compare_state")

    // 撤销取消订阅
    if (isNewSubClick && use_user.subscription_info_v2.status === SubStatus.AwaitCancelled) {
      if (landingComboMode.value === ILandingProductType.NewComboSub) {
        return navigateTo(unref(PAYMENT_PAGE_PATH))
      }
      const isV2 = use_user.subscription_info_v2.is_v2
      const desc = isV2
        ? useTranslateI18n("payment.cancelDialog.cancelDesc1")
        : useTranslateI18n(
            `payment.cancelDialog.${
              use_user.subscription_info.payment_type === switchListType.Yearly ? "cancelDesc1" : "cancelDesc"
            }`
          )
      usePaymentCancelDialog({
        title: useTranslateI18n("payment.topCard[1].btn[6]"),
        content() {
          return <div>{desc}</div>
        },
        async submit({ btnRef, closeDialog }) {
          btnRef.openLoading()
          await postNewSubRecover()
          btnRef.closeLoading()
          closeDialog()
          useCancelDowngradeOrCancelSubDialogSuccess(ISuccessType.cancel)
        }
      })
      return
      // 撤销取消降级
    } else if (isNewSubClick && use_user.subscription_info_v2.status === SubStatus.AwaitDownGrade) {
      if (landingComboMode.value === ILandingProductType.NewComboSub) {
        return navigateTo(unref(PAYMENT_PAGE_PATH))
      }
      usePaymentCancelDialog({
        title: useTranslateI18n("payment.topCard[1].btn[5]"),
        content() {
          return (
            <div>
              {useTranslateI18n(`payment.cancelDialog.downgradeDesc`, [
                use_user.subscription_info_v2.plan_name,
                use_user.subscription_info_v2.next_plan_name,
                use_user.subscription_info_v2.plan_name,
                use_user.subscription_info_v2.next_payment
              ])}
            </div>
          )
        },
        async submit({ btnRef, closeDialog }) {
          btnRef.openLoading()
          await postNewSubUpdate({ product_id: use_user.subscription_info_v2.plan_id })
          btnRef.closeLoading()
          closeDialog()
          useCancelDowngradeOrCancelSubDialogSuccess(ISuccessType.downgrade)
        }
      })
      return
      // reactivate
    } else if (isNewSubClick && [SubStatus.Failed3, SubStatus.Failed].includes(use_user.subscription_info_v2.status)) {
      if (landingComboMode.value === ILandingProductType.NewComboSub) {
        return navigateTo(unref(PAYMENT_PAGE_PATH))
      }
      const { trade_no } = await postNewSubRecover({
        purchase_uuid: usePurchaseReferer(),
        purchase_source: useRefWebRefferer()
      })
      useComboSubPausedResultDialog(trade_no)
      return
    }

    if (data?.compare_state === INewCompareState.CurrentSub) {
      // 落地页为当前套餐，直接回到payment购买页面
      if (landingComboMode.value === ILandingProductType.NewComboSub) {
        return navigateTo(unref(PAYMENT_PAGE_PATH))
      }
      return
    }

    // 升降级
    if (
      isNewSubClick &&
      (data.compare_state === INewCompareState.DownUpgrade || data.compare_state === INewCompareState.Upgrade)
    ) {
      const current_plan = (newSubOriginAllData.value || []).find(
        (item) => item.compare_state === INewCompareState.CurrentSub
      )
      const next_plan = (newSubOriginAllData.value || []).find((item) => item.id === data.id)
      // 降级
      if (data.compare_state === INewCompareState.DownUpgrade) {
        // 落地页降级不让点击降级，直接回到payment购买页面
        if (landingComboMode.value === ILandingProductType.NewComboSub) {
          return navigateTo(unref(PAYMENT_PAGE_PATH))
        }

        isNewSubDetailType.value = ISuccessResultType.DownType
        useComboSubDownGradeDialog({
          id: data.id,
          current_plan,
          next_plan,
          successCallback(trade_no) {
            paymentSuccessHandler({
              name: next_plan.name,
              credits: next_plan.credits,
              value: next_plan.u_discount_price || next_plan.price,
              char: next_plan.currency_char,
              currency: next_plan.currency,
              trade_no
            })
          },
          errorCallback() {
            isNewSubDetailType.value = IErrorSubResultType.DownType
            paymentErrorHandler()
          }
        })
      } else {
        // 升级
        isNewSubDetailType.value = ISuccessResultType.UpType
        useComboSubUpgradeDialog({
          id: data.id,
          current_plan,
          next_plan,
          successCallback(trade_no) {
            paymentSuccessHandler({
              name: next_plan.name,
              credits: next_plan.credits,
              value: next_plan.u_discount_price || next_plan.price,
              char: next_plan.currency_char,
              currency: next_plan.currency,
              trade_no
            })
          },
          errorCallback() {
            isNewSubDetailType.value = IErrorSubResultType.UpType
            paymentErrorHandler()
          }
        })
      }
      return
    }

    // 获取支付渠道：paddle或者本地支付的渠道
    await getCurrentAllPaymentContents({
      alink_uuid: landingUuid.value,
      custom_link_uuid: cusComboUuid.value,
      product_id: data.id
    })
    _pointPopUpClick({
      popup_name: "purchase"
    })

    // 如果是新订阅的补充包top up或者pay as you go模式
    if (isNewSubPksClick) {
      const payMethods = [] as Payment_Method[]
      unref(paddle_enable) && payMethods.push(Payment_Method.paddle)
      unref(payssion_enable) && payMethods.push(Payment_Method.payssion)
      use_user.subscription_info_v2.card_tail &&
        ![SubStatus.Failed, SubStatus.Failed3, SubStatus.Failed4].includes(use_user.subscription_info_v2.status) &&
        payMethods.push(Payment_Method.savedCard)
      // 补充包和pay as you go的desc不一样
      useComboSubBuyDialog({
        async onSubmit({
          close,
          closeDialog,
          selectValue
        }: {
          close: (...args: any[]) => any
          closeDialog: (...args: any[]) => any
          selectValue: Payment_Method
        }) {
          // 直接用历史卡支付
          if (selectValue === Payment_Method.savedCard) {
            try {
              isNewSubDetailType.value = ISuccessResultType.PksType
              const { trade_no } = await postNewSubBuyTopUp({
                product_id: data.id,
                purchase_uuid: usePurchaseReferer(),
                purchase_source: useRefWebRefferer()
              })
              closeDialog()
              paymentSuccessHandler({
                name: data.name,
                credits: data.credits,
                value: data.u_discount_price || data.price,
                char: data.char,
                currency: data.currency,
                trade_no
              })
            } catch (e) {
              isNewSubDetailType.value = IErrorSubResultType.PksType
              paymentErrorHandler()
            }
          } else {
            closeDialog()
            // 使用paddle和passion支付
            openPaymentDialog({
              data,
              method: selectValue
            })
          }
        },
        payMethods,
        data: data as ICardDetailData & INewComboProductListObj,
        desc: unref(isSubPayAsYougo)
          ? useTranslateI18n("payment.comboSub.t32", [
              { type: "static", class: "item-b" },
              { type: "static", class: "item-b" }
            ])
          : useTranslateI18n("payment.comboSub.t50", [{ type: "static", class: "item-b" }])
      })
      return
    }

    if (unref(paddle_enable) && unref(payssion_enable)) {
      usePaymentMethodDialog((value: Payment_Method) => {
        openPaymentDialog({
          data,
          method: value
        })
      })
    } else {
      openPaymentDialog({
        data
      })
    }
  }

  const handlerPayssionInfo = (data) => {
    return reactive({
      ...data,
      pkgName: data.name,
      credits: data.credits,
      perMoney: unref(isBackUnit)
        ? useNumFormat(data.per_credit_price) + data.currency_char
        : data.currency_char + useNumFormat(data.per_credit_price),
      total: unref(isBackUnit)
        ? useNumFormat(data.total_price) + data.currency_char
        : data.currency_char + useNumFormat(data.total_price),
      email: isCusComboAndNoLogin.value ? cusComboEmail.value : undefined
    })
  }

  // 打开paddle支付或者payssion
  const openPaymentDialog = async (options: {
    data?: (ICardDetailData & IComboProductListObj) | (ICardDetailData & INewComboProductListObj)
    method?: Payment_Method
  }) => {
    if ((options.method && options.method === Payment_Method.paddle) || (!options.method && unref(paddle_enable))) {
      setPaddleProduct(options.data)
      // 如果是新订阅页面
      if (unref(isNewSubMode)) {
        const { checkout_url, trade_no } = await postPaymentAirwallexCreate({
          method_id: IUserPaymentType.PaddleCard,
          platform_id: IUserPaymentPlatform.Paddle,
          product_id: options.data.id,
          alink_uuid: landingUuid.value,
          custom_link_uuid: cusComboUuid.value,
          ...landingComboUserInfo.value,
          // coupon_code: discount,
          is_sec_kill_activity: is_sec_kill_activity.value,
          ...(usePostToServerByPurchase() || {}),
          source:
            EVOTO_WEBSITE_TO_SERVER_PARAMS()[unref(isClientInlinePage) ? "evoto_DT_purchasePage" : useRoute().path],
          purchase_uuid: usePurchaseReferer(),
          purchase_source: useRefWebRefferer(),
          ads_conversion: channelHistry ? JSON.parse(channelHistry) : undefined
        })

        const isPacks = !Object.keys(options.data).includes("compare_state")

        usePaddleDialog({
          trade_no,
          checkout_url,
          isPassThrough: false,
          showAfterSubDesc: !isPacks,
          isPointer: false,
          showBottomTip: false,
          unit: paddleUnit,
          isBackUnit: unref(isBackUnit),
          orderInfo,
          uuid: unref(landingUuid),
          char: orderInfo.char,
          paymentFail(event) {
            isNewSubDetailType.value = isPacks ? IErrorSubResultType.PksType : IErrorSubResultType.SubType
            paymentErrorHandler()
          },
          paymentSuccess(event) {
            isNewSubDetailType.value = isPacks ? ISuccessResultType.PksType : ISuccessResultType.SubType
            paymentSuccessHandler({
              credits: event.credits,
              value: event.value,
              char: event.char,
              currency: event.currency,
              trade_no: event.trade_no
            })
          }
        })
        return
      }
    } else if (
      (options.method && options.method === Payment_Method.payssion) ||
      (!options.method && unref(payssion_enable))
    ) {
      showPaymentDialog({
        orderInfo: handlerPayssionInfo(options.data),
        radioSelectList: unref(pm_config),
        isBackUnit: unref(isBackUnit),
        callback: paymentVnCallback
      })
    }
  }

  // 设置paddle信息
  const setPaddleProduct = (
    data: (ICardDetailData & IComboProductListObj) | (ICardDetailData & INewComboProductListObj)
  ) => {
    for (const key in data) {
      orderInfo[key] = data[key]
    }
    orderInfo.char = data.currency_char
    orderInfo.credits = data.credits
    orderInfo.monthly_price = unref(isBackUnit)
      ? useNumFormat(data.per_credit_price) + data.currency_char
      : data.currency_char + useNumFormat(data.per_credit_price)
    orderInfo.price = unref(isBackUnit)
      ? useNumFormat(data.total_price) + data.currency_char
      : data.currency_char + useNumFormat(data.total_price)
    orderInfo.paddle_id = data.paddle_id
    orderInfo.showCustomTitle = data.name
    orderInfo.email = isCusComboAndNoLogin.value ? cusComboEmail.value : undefined

    isShowPaddle.value = true
  }

  // paddle支付失败
  const paymentErrorHandler = async () => {
    const lastResultUrl = redirectWithUrl(`${process.env.Website_Url}${unref(PAYMENT_RESULT)}`, {
      type: IPaymentResultType.error,
      show_more_info: 0,
      is_new_sub: unref(isNewSubMode),
      is_new_sub_type: unref(isNewSubDetailType) || IErrorSubResultType.SubType
    })
    location.href = lastResultUrl
  }
  // paddle支付成功
  const paymentSuccessHandler = async ({
    credits,
    value,
    char,
    currency,
    trade_no,
    name
  }: {
    credits?: number
    value?: string | number
    char?: string
    currency?: string
    trade_no?: string
    name?: string
  }) => {
    const resultValue = typeof value === "number" ? value : Number(value.replace(char, "").replace(/,/g, ""))

    const showMoreInfo = ref()

    // 非自定义购买链接 根据当前登录用户和购买张数来看
    if (!cusComboUuid.value) {
      showMoreInfo.value = +(
        credits >= showMoreInfoCredits || showMoreInfoCountry.includes(use_user.userRegisterCountry)
      )
      // 自定义购买链接 根据下发的商户和购买张数来看
    } else {
      showMoreInfo.value = +(
        credits >= showMoreInfoCredits || showMoreInfoCountry.includes(cusComboRegistryCountry.value)
      )
    }

    ;(unref(isNoLoginComboSpec) || unref(isNewSubMode)) && (showMoreInfo.value = 0)

    const resultUrl = `${process.env.Website_Url}${unref(PAYMENT_RESULT)}`
    // const resultUrl = `http://localhost:3010${unref(PAYMENT_RESULT)}`

    const paymentOuterUrl = `${process.env.Website_Url}/redirect/sensitiveLogin`

    const urlParams = {
      type: IPaymentResultType.success,
      name: name || orderInfo.showCustomTitle,
      currency: currency || currencyType.value,
      value: resultValue,
      show_more_info: showMoreInfo.value,
      trade_no: trade_no
    } as Record<string, any>

    if (unref(isNewSubMode)) {
      urlParams["is_new_sub"] = 1
      urlParams["is_new_sub_type"] = unref(isNewSubDetailType) || ISuccessResultType.SubType
    }

    if (landingComboUserInfo.value.gift_card_receiver) {
      urlParams.landing_combo_bf_gift_email = landingComboUserInfo.value.gift_card_receiver
    } else if (is_sec_kill_activity.value) {
      urlParams.is_sec_kill_activity = 1
    } else if (cusComboUuid.value) {
      urlParams.custom_link_uuid = cusComboUuid.value
    } else if (unref(isNoLoginComboSpec)) {
      urlParams.redeem_code = 1
    } else if (unref(isUsMobileComboRoute)) {
      urlParams.show_email_send = 1
    } else if (unref(isComboPromotionRoute)) {
      urlParams.is_combo_promotion = 1
    }

    const lastResultUrl = redirectWithUrl(resultUrl, urlParams)

    // 如果是paddle 软件内支付的话 跳成功链接到外链 -》 为了ad 抓取
    if (unref(isClientInlinePage)) {
      const { $bridge } = useNuxtApp()
      getJumpToken().then((r) => {
        const token = r.jump_token
        const lastPaymentResultUrl = redirectWithUrl(paymentOuterUrl, {
          uuid: token,
          redirect: lastResultUrl
        })
        $bridge.ipcRenderer.send("open-external-link", {
          href: lastPaymentResultUrl
        })
        const timeId = setTimeout(() => {
          location.href = lastResultUrl
          clearTimeout(timeId)
        })
      })
    } else {
      location.href = lastResultUrl
    }
  }

  const closePaddle = () => {
    isShowPaddle.value && (isShowPaddle.value = false)
    document.body.style.overflow = ""
  }

  // 本地支付
  // 本地dialog
  function showPaymentDialog(options: {
    orderInfo: IOrderInfo
    radioSelectList: IUserPaymentMethodObj[]
    isBackUnit: string | boolean
    callback: (...args: any[]) => any
  }) {
    usePaymentVnDialog(options)
  }

  // 越南支付回调
  async function paymentVnCallback({ orderInfo, discount = "", closeLoading, pm_id, pay_config }) {
    // 获取是passion还是airwallex平台
    const paymentType = pay_config.platform
    const { initAirWallexSdk } = useAirWallexHooks()
    // 选择创建订单的接口
    let postViteQrApi = cusComboUuid.value
      ? postViteQrLinkNoToken
      : paymentType === IUserPaymentPlatform.AirWallex
      ? postPaymentAirwallexCreate
      : postViteQrLink

    // 新订阅匹配逻辑
    if (unref(isNewSubMode)) {
      postViteQrApi = postPaymentAirwallexCreate
    }

    const uuid = cusComboUuid.value || landingUuid.value
    // const return_url = `${process.env.Website_Url}${unref(PAYMENT_RESULT)}`
    const return_url = `${process.env.Website_Url}${unref(PAYMENT_RESULT)}`
    // const return_url = `https://localhost:3010${unref(PAYMENT_RESULT)}`
    const resultParams = {} as any
    if (landingComboUserInfo.value.gift_card_receiver) {
      resultParams.landing_combo_bf_gift_email = landingComboUserInfo.value.gift_card_receiver
    } else if (is_sec_kill_activity.value) {
      resultParams.is_sec_kill_activity = 1
    } else if (cusComboUuid.value) {
      resultParams.custom_link_uuid = cusComboUuid.value
    } else if (unref(isNoLoginComboSpec)) {
      resultParams.redeem_code = 1
    } else if (unref(isUsMobileComboRoute)) {
      resultParams.show_email_send = 1
    } else if (unref(isComboPromotionRoute)) {
      resultParams.is_combo_promotion = 1
    }

    if (unref(isNewSubMode)) {
      resultParams["is_airwallex_and_new_sub"] = 1
      resultParams["show_more_info"] = 0
      resultParams["is_new_sub"] = 1
      // 判断是不是 pay as you go、top up pack
      resultParams["is_new_sub_type_packs"] = Number(!Object.keys(orderInfo).includes("compare_state"))
    }

    const passionLastResultUrl = redirectWithUrl(return_url, resultParams)

    const { redirect_url, trade_no, intent_id, client_secret, required_contact_field, currency } = (await postViteQrApi(
      {
        method_id: pay_config.id,
        platform_id: pay_config.platform,
        product_id: orderInfo.id,
        alink_uuid: landingUuid.value,
        custom_link_uuid: cusComboUuid.value,
        ...landingComboUserInfo.value,
        coupon_code: discount,
        is_sec_kill_activity: is_sec_kill_activity.value,
        pm_id,
        uuid,
        ...(usePostToServerByPurchase() || {}),
        return_url: passionLastResultUrl,
        source: EVOTO_WEBSITE_TO_SERVER_PARAMS()[unref(isClientInlinePage) ? "evoto_DT_purchasePage" : useRoute().path],
        purchase_uuid: usePurchaseReferer(),
        purchase_source: useRefWebRefferer(),
        ads_conversion: channelHistry ? JSON.parse(channelHistry) : undefined
      }
    )) as unknown as { [x: string]: any }

    // airwallex支付调起sdk
    if (paymentType === IUserPaymentPlatform.AirWallex) {
      // airwallex不会自动带上transaction_id，order_id，自己拼接上做后续result页面逻辑
      const airwallexLastResultUrl = redirectWithUrl(return_url, {
        ...resultParams,
        transaction_id: intent_id,
        order_id: trade_no,
        is_airwallex_and_new_sub: 1
      })

      await initAirWallexSdk({
        env: process.env.NUXT_APP_ENV === "production" ? "prod" : "demo",
        intent_id,
        client_secret,
        required_contact_field,
        currency,
        successUrl: airwallexLastResultUrl,
        failUrl: airwallexLastResultUrl,
        key: pay_config.key
      })
      // passion跳转
    } else {
      location.href = redirect_url
    }
    closeLoading()
  }

  return {
    comboProductListData,
    paddle_enable,
    payssion_enable,
    pm_config,
    isShowPaddle,
    orderInfo,
    paddleTopTip,
    paddleUnit,
    isBackUnit,
    isJp,
    loadingRef,
    landingComboProduct,
    landingCopyWrite,
    landingTitleCopyWrite,
    landingUuid,
    landingBuyButtonDisabled,
    isClientInlinePage,
    cusComboProduct,
    cusComboLinkShow,
    cusComboUuid,
    cusComboQuotation,
    cusComboCompany,
    cusComboEmail,
    cusComboType,
    comboProductListDescs,
    landingDownloadProduct,
    landingComboBfProducts,
    landingComboBfActivity,
    landingComboTimeValue,
    ProductKey,
    comboPriceRange,
    isHotActivityStart,
    isSubPayAsYougo,
    initCusComboProduct,
    initLandingComboProduct,
    openLoading,
    closeLoading,
    initComboProductData,
    cardBtnClick,
    paymentErrorHandler,
    paymentSuccessHandler,
    closePaddle,
    initDownloadLandingComboProduct,
    getDownloadLandingCombo,
    initLandingComboBf,
    getLandingComboBfProducts,
    getLandingComboProductFn,
    initNewComboSubProduct,
    initNewComboSubPacksProduct,
    isNoLoginComboSpec,
    landingComboTimeStamp,
    coverImage,
    videoLink,
    isComboPromotionRoute,
    landingComboMode,
    landingComboSubProduct,
    comboProductList
  }
}
