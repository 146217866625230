import { createVNode, render } from "vue"
import ThanksDialog from "~~/components/pages/download/ThanksDialog.vue"
import { JoinFacebookSource } from "@/constant/mp"
import { GroupLinkType, ThanksDialogTextType } from "@/composables/api/common"

type UseDownloadThanksDialogParams = {
  textType?: ThanksDialogTextType
  pointPageTitle?: string
  source?: JoinFacebookSource
  url?: string
  type?: GroupLinkType
  title?: {
    t1: string
    t2: string
  }
}
export function useDownloadThanksDialog(options: UseDownloadThanksDialogParams = {}) {
  const vm = createVNode(ThanksDialog, {
    ...options
  })
  const { vueApp } = useNuxtApp()
  vm.appContext = vueApp._context
  render(vm, document.createElement("div"))
}
