<template>
  <div class="promotion-end-dialog">
    <img src="@/assets/images/pages/landingComboBf/soldout.png" />
    <span class="t1">{{ useTranslateI18n("landing.landing_promotion.t1") }}</span>
    <CommonButton ref="btn1" :button-style="buttonStyle" @btn-click="go"
      ><CommonAnimationScrollText>{{
        useTranslateI18n(`landing.landing_promotion.join`)
      }}</CommonAnimationScrollText></CommonButton
    >
  </div>
</template>

<script setup lang="ts">
import { scopeEnum } from "~~/constant/diversion"
import { useUser } from "@/store/user"
import { DOWNLOAD_INFO_KEY } from "~~/constant/pages/download"
import { DOWNLOAD_GUIDE_PAGE_PATH } from "@/constant/route"

const btn1 = ref()

const buttonStyle = reactive({
  width: "100%",
  background: "#FFE050",
  color: `rgba(0, 0, 0, 0.85)`,
  borderRadius: "60px",
  height: "42px",
  fontSize: "14px",
  fontFamily: "Inter-Medium"
})

const emit = defineEmits(["btnClick"])

const go = async () => {
  let use_user = useUser()
  try {
    btn1.value && btn1.value.openLoading()
    const { style: type, addr: url } = await getContactGroupUrlV2(
      scopeEnum.startFreeTrail,
      use_user.userEmail,
      useReferrer2().value || useReferrer().value
    )
    const params = {
      textType: ThanksDialogTextType.Type1,
      url,
      type
    }
    setLocalStorage(DOWNLOAD_INFO_KEY, JSON.stringify(params))
    emit("btnClick")
    return navigateTo({
      path: unref(DOWNLOAD_GUIDE_PAGE_PATH)
    })
  } finally {
    btn1.value && btn1.value.closeLoading()
  }
}
</script>

<style scoped lang="scss">
.promotion-end-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  > img {
    width: 104px;
  }
  .t1 {
    font-size: 24px;
    @include fontSemibold;
    color: #000;
    margin: 24px 0;
    line-height: normal;
  }
}
</style>
