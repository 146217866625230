import ComboBfPendingDialog from "~~/components/common/ComboBfPendingDialog.vue"
import { createVNode, render } from "vue"

export const useLandingComboBfPendingDialog = (options: {
  width?: number
  title?: string
  showCountDown?: boolean
  countDownTimestamp?: number
  countDownTimeOutHandler?: (...args: any[]) => any
}) => {
  // 挂载  因为dialog是teleport 无需append to body
  const vm = createVNode(ComboBfPendingDialog, {
    ...options
  })
  const { vueApp } = useNuxtApp()
  vm.appContext = vueApp._context
  render(vm, document.createElement("div"))
}
