import { CookiesOptions, gTagPageWhiteList } from "~~/constant/cookies"
import { LocalStorageKey } from "~~/constant/cache"
import { nanoid } from "nanoid"

class ShareSalesPoint {
  static instance: ShareSalesPoint = null
  static getInstance(): ShareSalesPoint {
    this.instance = this.instance ? this.instance : new ShareSalesPoint()
    return this.instance
  }
  setEvent(value, type, order_id?) {
    if (process.client) {
      const isEnabled = useIsPoint(CookiesOptions.Marketing)
      if (!isEnabled) {
        return
      }
      // 区分环境id
      const merchantID = process.env.NUXT_APP_ENV === "production" ? 140229 : 139744
      const img = new Image()
      order_id = order_id || nanoid()
      img.src = `https://www.shareasale.com/sale.cfm?tracking=${order_id}&amount=${value}&merchantID=${merchantID}&transtype=sale&currency=${type}`
    }
  }
}

export default ShareSalesPoint.getInstance()
