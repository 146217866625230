import ComboBfTimeoutDialog from "~~/components/common/ComboBfTimeoutDialog.vue"
import { createVNode, render } from "vue"

export const useLandingComboBfTimeoutDialog = (options?: {
  title?: string
  desc?: string
  showDesc?: boolean
  width?: number
}) => {
  // 挂载  因为dialog是teleport 无需append to body
  const vm = createVNode(ComboBfTimeoutDialog, {
    ...options
  })
  const { vueApp } = useNuxtApp()
  vm.appContext = vueApp._context
  render(vm, document.createElement("div"))
}
