import PaymentVnDialog from "@/components/pages/payment/PaymentVnDialog.vue"
import { createVNode, render } from "vue"
import { IOrderInfo } from "@/types/components/pages/payment/paymentVnDialog"
import { IUserPaymentMethodObj } from "@/composables/api/combo"

export const usePaymentVnDialog = (options: {
  orderInfo?: IOrderInfo
  radioSelectList?: IUserPaymentMethodObj[]
  isBackUnit?: boolean | string
  callback?: (...args: any[]) => any
}) => {
  const { orderInfo, radioSelectList, isBackUnit, callback } = options
  const vm = createVNode(PaymentVnDialog, {
    orderInfo,
    radioSelectList,
    isBackUnit,
    callback
  })
  const { vueApp } = useNuxtApp()
  vm.appContext = vueApp._context
  render(vm, document.createElement("div"))
}
