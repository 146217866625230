<template>
  <CommonDialog v-model="visible" :dialog-style="dialogStyle" popper-class="thanks-dialog_1">
    <PagesDownloadThanksDialogWrapper
      :text-type="textType"
      :source="source"
      :type="type"
      :url="url"
      :title="title"
      :point-page-title="pointPageTitle"
    />
  </CommonDialog>
</template>
<script lang="ts" setup>
import { JoinFacebookSource } from "~~/constant/mp"
import { PropType } from "vue"
import { GroupLinkType, ThanksDialogTextType } from "@/composables/api/common"

defineProps({
  textType: {
    type: Number as PropType<ThanksDialogTextType>,
    default: null
  },
  source: {
    type: String as PropType<JoinFacebookSource>,
    default: JoinFacebookSource.Website
  },
  type: {
    type: Number as PropType<GroupLinkType>,
    default: GroupLinkType.LINE
  },
  url: {
    type: String,
    default: ""
  },
  title: {
    type: Object as PropType<{ t1: string; t2: string }>,
    default: () => ({})
  },
  pointPageTitle: {
    type: String,
    default: ""
  }
})

const visible = ref(true)

const dialogStyle = computed(() => {
  return {
    width: "623px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 36px 70px rgba(0, 0, 0, 0.08), 0px 0px 25px rgba(0, 0, 0, 0.1)"
  }
})

function close() {
  visible.value = false
}
</script>
<style lang="scss" scoped></style>

<style lang="scss">
.welcome-page-dialog {
  &.thanks-dialog_1 {
    @include device-max-width-600 {
      .content {
        width: 80%;
        .content-d {
          width: 100% !important;
          .thanks-dialog-container {
            padding: 20px;
            .advantage-wrap {
              padding: 15px;
            }
            .get-start {
              flex-direction: column;
              padding: 10px;
              height: auto;
              .get-start-text {
                text-align: center;
                margin-bottom: 10px;
              }
            }
            .title {
              text-align: center;
            }
          }
          .advantage-wrap {
            width: 100%;
          }
          .advantage-wrap-bottom {
            flex-direction: column;
            .profile-wrap {
              margin-top: 10px;
              flex-direction: column;
              justify-content: center;
            }
          }
        }
      }
    }
    @include device-max-width-375 {
      .content {
        width: 75%;
        .close-icon {
          right: -32px;
        }
      }
    }
  }
}
</style>
