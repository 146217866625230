import { IType } from "@/types/pages/dialog/purchaseDialog"
import { useTranslateI18n } from "~~/composables/store/i18n-country"

export const getContentValue = (type, name) => {
  const contentDict = {
    [IType.Purchase]: useTranslateI18n(`payment.purchaseDialog.contentPurchase`, [name]),
    [IType.Other]: useTranslateI18n(`payment.purchaseDialog.content`, [name])
  }
  return contentDict[type]
}
